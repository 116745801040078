import { createContext, useCallback, useState } from "react";
import { Auth } from "aws-amplify";

export type AuthContextType = {
  isLoggedIn: boolean;
  user: UserState | null;
  userDevice: string | null;
  account: AccountState | null;
  loginLoading: boolean;
  loginLoadingOp: {
    start: () => void;
    finish: () => void;
  };
  onLogin: (user: any) => void;
  onSocialLogin: (user: any) => void;
  onLogout: () => void;
  setAccountDetail: (params: AccountState) => void;
  setInitialAccess: (value: boolean) => void;
  setUserDetail: (params: SetUserState) => void;
  setUserDevice: (deviceType: string) => void;
  assessLoggedInState: () => void;
};

const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  user: null,
  userDevice: null,
  account: null,
  loginLoading: false,
  loginLoadingOp: {
    start: () => {},
    finish: () => {},
  },
  setAccountDetail: (params: AccountState) => {},
  setInitialAccess: (value: boolean) => {},
  setUserDetail: (params: SetUserState) => {},
  setUserDevice: (deviceType: string) => {},
  onLogin: (user: any) => {},
  onSocialLogin: (user: any) => {},
  onLogout: () => {},
  assessLoggedInState: () => {},
});

interface CognitoUserSession {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}
interface UserState {
  id: string;
  username: string;
  email: string;
  email_verified: boolean;
  phone_number: string;
  phone_number_verified: boolean;
  birthdate: string;
  signInUserSession: CognitoUserSession;
  provider: string;
}

interface SetUserState {
  username: string;
  birthdate: string;
}

interface AccountState {
  firstname?: string | null;
  lastname?: string | null;
  gender?: string | null;
  current_address?: string | null;
  current_country?: string | null;
  birth_address?: string | null;
  birth_country?: string | null;
  image?: string | null;
  nickname?: string | null;
  middlename?: string | null;
  initial_access?: string | null;
  mail_activate?: string | null;
}

export const AuthContextProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [user, setUser] = useState<UserState | null>(null);
  const [account, setAccount] = useState<AccountState | null>(null);
  const [userDevice, setUserDevice] = useState<string | null>(null);

  const startLoginLoading = useCallback(() => setLoginLoading(true), []);
  const finishLoginLoading = useCallback(() => setLoginLoading(false), []);

  const setAccountDetail = (params: AccountState) => {
    setAccount((prevState) => {
      return { ...prevState, ...params };
    });
  };

  const setUserDetail = (params: SetUserState) => {
    if (!user) return;

    user.birthdate = params.birthdate;
    user.username = params.username;

    setUser((prevState: any) => {
      return { ...prevState, ...user };
    });
  };

  const setInitialAccess = (value: boolean) => {
    if (!account) return;

    account.initial_access = value.toString();

    setAccount((prevState: any) => {
      return { ...prevState, ...account };
    });
  };

  const logoutHandler = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setIsLoggedIn(false);
      localStorage.removeItem("idToken");
      localStorage.removeItem("alcholic_agreement");
    } catch (error) {
      // console.log("Error signing out : ", error);
    }
  };

  const loginHandler = (userData: any) => {
    setIsLoggedIn(true);
    setUser({
      id: userData.attributes.sub,
      username: userData.attributes.preferred_username,
      email: userData.attributes.email,
      email_verified: userData.attributes.email_verified,
      phone_number: userData.attributes.phone_number,
      phone_number_verified: userData.attributes.phone_number_verified,
      birthdate: userData.attributes.birthdate,
      signInUserSession: {
        accessToken: userData.signInUserSession.accessToken.jwtToken,
        idToken: userData.signInUserSession.idToken.jwtToken,
        refreshToken: userData.signInUserSession.refreshToken.jwtToken,
      },
      provider:
        userData?.signInUserSession?.idToken?.payload?.identities?.[0]
          ?.providerName || "custom",
    });
    localStorage.setItem(
      "idToken",
      userData.signInUserSession.idToken.jwtToken
    );
  };

  const socialLoginHandler = (userData: any) => {
    setIsLoggedIn(true);

    setUser({
      id: userData?.username,
      username:
        userData?.signInUserSession?.idToken?.payload?.preferred_username,
      email: userData?.signInUserSession?.idToken?.payload?.email,
      email_verified:
        userData?.signInUserSession?.idToken?.payload?.email_verified,
      phone_number: "",
      phone_number_verified: false,
      birthdate: userData?.signInUserSession?.idToken?.payload?.birthdate,
      signInUserSession: {
        accessToken: userData.signInUserSession.accessToken.jwtToken,
        idToken: userData.signInUserSession.idToken.jwtToken,
        refreshToken: userData.signInUserSession.refreshToken.jwtToken,
      },
      provider:
        userData?.signInUserSession?.idToken?.payload?.identities?.[0]
          ?.providerName || "custom",
    });
    localStorage.setItem(
      "idToken",
      userData.signInUserSession.idToken.jwtToken
    );
  };

  const assessLoggedInState = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      if (!authUser) return;

      setUser({
        id: authUser?.username || authUser?.attributes?.sub,
        username:
          authUser?.attributes?.preferred_username || authUser?.username,
        email:
          authUser?.attributes?.email ||
          authUser?.signInUserSession?.idToken?.payload?.email,
        email_verified:
          authUser?.attributes?.email_verified ||
          authUser?.signInUserSession?.idToken?.payload?.email_verified,
        phone_number: authUser?.attributes?.phone_number || "",
        phone_number_verified:
          authUser?.attributes?.phone_number_verified || false,
        birthdate:
          authUser?.attributes?.birthdate ||
          authUser?.signInUserSession?.idToken?.payload?.birthdate,
        signInUserSession: {
          accessToken: authUser?.signInUserSession?.accessToken?.jwtToken,
          idToken: authUser?.signInUserSession?.idToken?.jwtToken,
          refreshToken: authUser?.signInUserSession?.refreshToken?.jwtToken,
        },
        provider:
          authUser?.signInUserSession?.idToken?.payload?.identities?.[0]
            ?.providerName || "custom",
      });
      setIsLoggedIn(true);

      if (!userDevice) {
        const userAgent = window.navigator.userAgent;
        const isMobileDevice =
          /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(userAgent);
        if (
          window.matchMedia("(display-mode: fullscreen)").matches ||
          (window.navigator as any).standalone
        ) {
          setUserDevice("pwa");
        } else if (isMobileDevice) {
          setUserDevice("mobile");
        } else setUserDevice("browser");
      }
    } catch (error) {
      // console.log("not logged in", error);
      setUser(null);
      setIsLoggedIn(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: user as any,
        userDevice: userDevice,
        account: account,
        loginLoading: loginLoading,
        loginLoadingOp: {
          start: startLoginLoading,
          finish: finishLoginLoading,
        },
        setAccountDetail: setAccountDetail,
        setInitialAccess: setInitialAccess,
        setUserDetail: setUserDetail,
        setUserDevice: setUserDevice,
        isLoggedIn: isLoggedIn,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        onSocialLogin: socialLoginHandler,
        assessLoggedInState: assessLoggedInState,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
