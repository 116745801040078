import { useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import BootstrapPassword from "../../components/Inputs/BootstrapPassword";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  currentPasswordValidation,
  newPasswordValidation,
  confirmNewPasswordValidation,
} from "../../utils/validations";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  const schema = Yup.object().shape({
    current_password: currentPasswordValidation,
    new_password: newPasswordValidation,
    confirm_password: confirmNewPasswordValidation,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        data?.current_password,
        data?.new_password
      );
      setError("");
      setStep(1);
      reset({});
      toast.success("パスワードの変更に成功しました！", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "dark",
        style: {
          background: `rgba(0, 0, 0, 0.9)`,
        },
        bodyStyle: {
          background: `rgba(0, 0, 0, 0.9)`,
        },
        progressStyle: {
          background: `rgba(204, 204, 204, 0.9)`,
        },
      });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      <Box
        sx={{
          minHeight: `calc(100vh - 216px)`,
        }}
      >
        {step === 0 && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Container sx={{ paddingBottom: "25px" }}>
                <Paper sx={{ width: 1, paddingX: "20px", paddingY: "20px" }}>
                  <Grid sx={{ paddingY: 0.5 }}>
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      パスワードを更新する場合は、以下に現在のパスワードと新しいパスワードを入力してください。
                    </Typography>
                  </Grid>
                  <Grid sx={{ paddingY: 0.5 }}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <FormControl sx={{ my: 1, width: 1 }}>
                            <InputLabel
                              shrink
                              htmlFor="current_password"
                              sx={{
                                transform: "none",
                                fontSize: "12px",
                              }}
                            >
                              現在のパスワード
                            </InputLabel>
                            <BootstrapPassword
                              field={field}
                              sx={{ width: 1 }}
                              id="current_password"
                              placeholder="現在のパスワードを入力してください"
                            />
                          </FormControl>
                        );
                      }}
                      name="current_password"
                      control={control}
                    />
                    {!!errors?.current_password?.message && (
                      <FormHelperText error id="current_password_helper">
                        <>{errors?.current_password?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid sx={{ paddingY: 0.5 }}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <FormControl sx={{ my: 1, width: 1 }}>
                            <InputLabel
                              shrink
                              htmlFor="new_password"
                              sx={{
                                transform: "none",
                                fontSize: "12px",
                              }}
                            >
                              新しいパスワード
                            </InputLabel>
                            <BootstrapPassword
                              field={field}
                              sx={{ width: 1 }}
                              id="new_password"
                              placeholder="新しいパスワードを入力してください"
                            />
                          </FormControl>
                        );
                      }}
                      name="new_password"
                      control={control}
                    />
                    {!!errors?.new_password?.message && (
                      <FormHelperText error id="new_password_helper">
                        <>{errors?.new_password?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid sx={{ paddingY: 0.5 }}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <FormControl sx={{ my: 1, width: 1 }}>
                            <InputLabel
                              shrink
                              htmlFor="confirm_password"
                              sx={{
                                transform: "none",
                                fontSize: "12px",
                              }}
                            >
                              新しいパスワードの再確認
                            </InputLabel>
                            <BootstrapPassword
                              field={field}
                              sx={{ width: 1 }}
                              id="confirm_password"
                              placeholder="新しいパスワードの再確認を入力してください"
                            />
                          </FormControl>
                        );
                      }}
                      name="confirm_password"
                      control={control}
                    />
                    {!!errors?.confirm_password?.message && (
                      <FormHelperText error id="confirm_password_helper">
                        <>{errors?.confirm_password?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>

                  {error && (
                    <Box
                      sx={{
                        width: 1,
                        paddingY: 1,
                        border: 1,
                        color: "#9f1831",
                        borderColor: "#9f1831",
                        borderBlockColor: "#9f1831",
                        margin: "20px 0 10px 0",
                      }}
                    >
                      <Typography align="center">{t(error)}</Typography>
                    </Box>
                  )}

                  <ButtonSubmit
                    type="submit"
                    sx={{ my: 3, width: 1, fontWeight: 600 }}
                    variant="contained"
                    disabled={loading}
                  >
                    パスワードを変更する
                  </ButtonSubmit>
                </Paper>
              </Container>
            </form>
            {loading && <Loader />}
          </>
        )}
        {step === 1 && (
          <Paper sx={{ padding: 3 }}>
            <Typography sx={{ paddingBottom: 4 }}>
              変更が完了しました。
            </Typography>
            <Button
              type="button"
              onClick={() => navigate("/settings")}
              sx={{
                width: 1,
                color: "#000000",
                fontWeight: 600,
                borderColor: "#000000",
              }}
              variant="outlined"
            >
              設定画面に戻る
            </Button>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default ChangePassword;
