import { useContext } from "react";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useAccountApi } from "../../custom-hooks/apis/use-account-api";
import ProfileForm from "./ProfileForm";
import AuthContext from "../../custom-hooks/use-auth-context";

const ProfilePage = () => {
  const { getAccount } = useAccountApi();
  const authCtx = useContext(AuthContext);

  useQuery([QueryKeys.account, authCtx.user?.signInUserSession.idToken], () => {
    if (authCtx.user?.signInUserSession.idToken) return getAccount();
    return;
  });

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - 216px)`,
      }}
    >
      <ProfileForm />
    </Box>
  );
};

export default ProfilePage;
