import AwsApi from "./aws-api";

export default class appPaymentApi extends AwsApi {
  async getRedirectPayment(params: any): Promise<any> {
    return await this.get("payment/redirect/" + process.env.REACT_APP_ENVNAME, {
      params: params,
    });
  }

  async createPayment(params: any): Promise<any> {
    return await this.post(
      "payment/create/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }

  async payQuick(params: any): Promise<any> {
    return await this.post(
      "payment/quick/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }

  async getCardInformation(): Promise<any> {
    return await this.get("payment/card/" + process.env.REACT_APP_ENVNAME);
  }

  async getClientSecret(): Promise<any> {
    return await this.post("payment/card/" + process.env.REACT_APP_ENVNAME);
  }
}
