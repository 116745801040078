import * as React from "react";
import {
  Typography,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function SettingsPage() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      <Paper sx={{ width: 1, paddingY: "15px", marginBottom: "30px" }}>
        <MenuList dense sx={{ fontWeight: "600" }}>
          <MenuItem disabled sx={{ paddingY: "10px" }}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>アカウント設定</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/profile")}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                プロフィール変更
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/account-management")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                アカウント管理
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          {/* <MenuItem>
            <ListItemText>
              <Typography>詳細設定</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos sx={{ minWidth: "25px", fontSize: "1rem !important" }} fontSize="small" />
            </ListItemIcon>
          </MenuItem> */}
          <MenuItem disabled sx={{ paddingY: "10px" }}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>サポート</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/terms-of-use")}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                利用規約
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/privacy-policy")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                プライバシーポリシー
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/management-company")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                運営会社
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/commerce")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                特定商取引法に基づく表記
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/app-info")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                このアプリについて
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/contact-us")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                お問い合わせ
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem disabled sx={{ paddingY: "10px" }}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>マニュアル</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/manual/regist")}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                御酒飲帳の会員登録追加手順
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/manual/login")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                御酒飲帳のログイン手順
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/manual/record")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                御酒飲帳の「飲んだ履歴」確認手順
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ marginY: "10px" }}
            onClick={() => navigate("/manual/enjoy")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600", color: "#292929" }}>
                御酒飲帳で日本酒を楽しむ
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "35px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
        </MenuList>
      </Paper>
    </Box>
  );
}
