import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import { Controller, FieldValues, useForm } from "react-hook-form";
import AuthContext from "../../custom-hooks/use-auth-context";
import PasswordField from "../Inputs/PasswordField";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { emailValidation, passwordValidation } from "../../utils/validations";
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { FACEBOOK_ICON, GOOGLE_ICON } from "../../assets/icons";
import ButtonGoogle from "../Buttons/ButtonGoogle";
import GlobalDataContext from "../../custom-hooks/use-global-data";

const LoginForm = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [t] = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const login_redirect_url = urlParams.get("login_redirect_url");

  const schema = Yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    authCtx.loginLoadingOp.start();
    try {
      const user = await Auth.signIn(data.email, data.password);
      authCtx.onLogin(user);

      if (login_redirect_url) {
        let redirect_url = login_redirect_url;
        const paramsObject = Object.fromEntries(urlParams.entries());
        for (const [key, value] of Object.entries(paramsObject)) {
          if (key !== "login_redirect_url") {
            redirect_url += "&" + key + "=" + value;
          }
        }
        authCtx.loginLoadingOp.finish();

        const BASE_URL = window.location.protocol + "//" + window.location.host;
        const red_url = redirect_url.replace(BASE_URL, "");
        navigate(red_url);
      } else {
        authCtx.loginLoadingOp.finish();
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      setError(error?.message);
      authCtx.loginLoadingOp.finish();

      //認証されていないユーザーの場合、確認コードを再送信する
      if (error.code === "UserNotConfirmedException") {
        const UserPoolId = process.env.REACT_APP_AWS_USER_POOLS_ID;
        const params = {
          UserPoolId,
          Limit: 60,
          Filter: `email="${data.email}"`,
        };

        const client = new CognitoIdentityProviderClient({
          apiVersion: "2016-04-18",
          region: process.env.REACT_APP_AWS_PROJECT_REGION,
          credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || "",
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "",
          },
        });

        const response = await client.send(new ListUsersCommand(params));

        if (response?.Users && response?.Users?.length <= 0) {
          throw new Error("ユーザーがありません。");
        } else {
          navigate("/confirm-phone-number", {
            state: {
              username: response.Users ? response?.Users[0].Username : "",
              email: data.email,
            },
            replace: true,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // async function signInWithGoogle() {
  //   try {
  //     authCtx.loginLoadingOp.start();
  //     const user = await Auth.federatedSignIn({
  //       provider: CognitoHostedUIIdentityProvider.Google,
  //     });
  //     if (login_redirect_url)
  //       sessionStorage.setItem("social_redirect_url", login_redirect_url);
  //     console.log(user);
  //   } catch (error) {
  //     authCtx.loginLoadingOp.finish();
  //     console.log("Error signing in with Google:", error);
  //   }
  // }

  const signInWithFacebook = async () => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });
    } catch (error) {
      // console.log("Error signing in with Facebook:", error);
    }
  };

  return (
    <Box sx={{ width: 1 }}>
      <Typography
        sx={{ paddingY: 2.5 }}
        component="h1"
        variant="h6"
        align="center"
        fontWeight="600"
      >
        アカウントにサインインする
      </Typography>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="email"
                sx={{ my: 1 }}
                id="filled-basic"
                placeholder="メールを入力してください"
              />
            )}
            name="email"
            control={control}
          />
          {!!errors?.email?.message && (
            <FormHelperText error id="email_helper">
              <>{errors?.email?.message}</>
            </FormHelperText>
          )}

          <Controller
            render={({ field }) => {
              return (
                <FormControl sx={{ my: 1, width: 1 }}>
                  <PasswordField
                    field={field}
                    id={"password"}
                    placeholder={"パスワードを入力してください"}
                  />
                </FormControl>
              );
            }}
            name="password"
            control={control}
          />
          {!!errors?.password?.message && (
            <FormHelperText error id="password_helper">
              <>{errors?.password?.message}</>
            </FormHelperText>
          )}

          {error && (
            <Box
              sx={{
                width: 1,
                paddingY: 1,
                border: 1,
                color: "#9f1831",
                borderColor: "#9f1831",
                borderBlockColor: "#9f1831",
                margin: "35px 0 10px 0",
              }}
            >
              <Typography align="center">{t(error)}</Typography>
            </Box>
          )}

          <ButtonSubmit
            type="submit"
            sx={{ my: 2, width: 1, fontWeight: 600 }}
            variant="contained"
            disabled={loading}
          >
            ログイン
          </ButtonSubmit>
        </form>
        <Box textAlign={"center"}>
          <Link
            to="/forgot-password"
            style={{
              color: "#959DFD",
              textDecoration: "none",
              fontSize: "0.8rem",
            }}
          >
            パスワードをお忘れですか？
          </Link>
        </Box>
        <Box sx={{ my: 3 }}>
          {/* <Box sx={{ marginY: 2 }}>
            <ButtonGoogle
              variant="contained"
              sx={{
                width: 1,
                py: 1,
              }}
              onClick={signInWithGoogle}
              startIcon={
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  src={GOOGLE_ICON}
                />
              }
            >
              Googleでログインする
            </ButtonGoogle>
          </Box> */}
          {/* <Box sx={{ marginY: 2 }}>
            <Button
              variant="contained"
              sx={{
                width: 1,
                color: "#fff",
                justifyContent: "flex-start",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              onClick={signInWithFacebook}
              startIcon={
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  src={FACEBOOK_ICON}
                />
              }
            >
              Facebookでログインする
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
