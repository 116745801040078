import AwsApi from "./aws-api";

export default class appHistoryApi extends AwsApi {
  async getHistory(): Promise<any> {
    return await this.get("drunk/" + process.env.REACT_APP_ENVNAME);
  }

  async getHistoryDetail(post_id: string): Promise<any> {
    return await this.get("drunk/detail/" + process.env.REACT_APP_ENVNAME, {
      params: { post_id: post_id },
    });
  }

  async getAlcoholDetail(sake_id: string): Promise<any> {
    return await this.get("sake/detail/" + process.env.REACT_APP_ENVNAME, {
      params: { sake_id: sake_id },
    });
  }
}
