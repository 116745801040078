import React, { useContext } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import WalletIcon from "@mui/icons-material/Wallet";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { usePaymentApp } from "../../custom-hooks/apis/use-payment-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const PaymentMethodList = () => {
  const navigate = useNavigate();
  const { loading } = useContext(GlobalDataContext);
  const { getCardInformation } = usePaymentApp();

  const { data, isLoading } = useQuery([QueryKeys.getCardInformation], () =>
    getCardInformation()
  );

  const handleUpdateCardInformation = async () => {
    navigate("/change-payment-information?return_url=" + window.location.href);
  };

  return isLoading || loading ? (
    <Loader />
  ) : data?.card ? (
    <Container>
      <Paper sx={{ padding: 2 }}>
        <Typography
          align="center"
          sx={{
            paddingTop: "15px",
            fontSize: "15pt",
            fontWeight: 700,
          }}
        >
          支払い情報
        </Typography>
        <Grid container spacing={1} sx={{ paddingTop: 5, paddingBottom: 2 }}>
          <Grid item xs={2}>
            <IconButton>
              <WalletIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: "4px" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
              **** {data?.card?.last4} {data?.card?.brand}
            </Typography>
            <Typography sx={{ color: `rgba(0, 0, 0, 0.54)`, fontSize: "12px" }}>
              有効期限： {("00" + data?.card?.exp_month).slice(-2)}/
              {data?.card?.exp_year}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: "4px" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => handleUpdateCardInformation()}
            >
              <EditIcon style={{ fontSize: "15px", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                編集する
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  ) : (
    <Container>
      <Paper sx={{ padding: 2 }}>
        <Typography align="center" sx={{ fontSize: "18px", fontWeight: 600 }}>
          支払い情報
        </Typography>
        <Grid sx={{ paddingTop: 8, paddingBottom: 5 }}>
          <Typography align="center">
            このアカウントには支払い情報が存在しません。初回決済時にご登録頂いた決済情報がこちらに表示されます
            {/* このアカウントには支払い情報が存在しません。こちらのメニューから登録しておくと、決済時に決済情報を入力することなく素早く決済することが可能です。 */}
          </Typography>
        </Grid>
        {/* <Grid sx={{ paddingY: 2 }}>
          <ButtonSubmit
            sx={{ whiteSpace: "pre-wrap", width: 1 }}
            onClick={() => handleUpdateCardInformation()}
          >
            支払い情報を登録する <br />
            （クレジットカードのみ）
          </ButtonSubmit>
        </Grid> */}
      </Paper>
    </Container>
  );
};

export default PaymentMethodList;
