import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const RequireAuth = () => {
  const handleLogin = () => {
    const baseUrl = window.location.origin;
    let redirect_url =
      baseUrl + "/login/quick?login_redirect_url=" + window.location.href;
    window.location.href = redirect_url;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: "150px", marginBottom: "50px" }}
      >
        <LockIcon sx={{ fontSize: "75px" }} />
      </Box>

      {/* <Typography
        fontWeight={550}
        component="h2"
        variant="h6"
        align="center"
      ></Typography>

      <Typography fontSize={"small"} align="center"></Typography> */}

      <Typography
        sx={{ paddingY: 1, fontWeight: 550, fontSize: "1rem" }}
        component="h1"
        variant="h6"
        align="center"
      >
        こちらのコンテンツはログインが必要です。
      </Typography>
      <Typography
        sx={{ fontSize: "0.8rem", width: 0.8, marginX: "auto" }}
        align="center"
      >
        下記ポタンよりログインまたは新規会員登録 を行ってください。
      </Typography>

      <Box marginY={3} display="flex" justifyContent="center">
        <ButtonSubmit sx={{ width: 0.9 }} onClick={handleLogin}>
          ログイン・会具登録
        </ButtonSubmit>
      </Box>
    </Box>
  );
};

export default RequireAuth;
