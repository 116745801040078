import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

interface DrinkMenuItemProps {
  title: string;
  desc1: string;
  desc2: string;
  icon: JSX.Element;
  handleClick: () => void;
}

const DrinkMenuItem = ({
  title,
  desc1,
  desc2,
  icon,
  handleClick,
}: DrinkMenuItemProps) => {
  return (
    <Card
      elevation={2}
      onClick={handleClick}
      sx={{ display: "flex", padding: 1.5, marginY: 1.5 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {icon}
        </CardContent>
      </Box>
      <CardContent sx={{ flex: "1" }}>
        <Typography variant="h6" component="h2" sx={{ fontSize: "20px" }}>
          <strong>{title}</strong>
        </Typography>
        <Typography fontSize="12px">{desc1}</Typography>
        <Typography fontSize="12px">{desc2}</Typography>
      </CardContent>
    </Card>
  );
};

export default DrinkMenuItem;
