import React from "react";
import {
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";

const About = () => {
  const { getAboutCompanyInfo } = useCommonApi();
  const TOPICS_ID = 4;

  const result = useQuery([QueryKeys.aboutCompany, TOPICS_ID], () =>
    getAboutCompanyInfo(TOPICS_ID)
  );

  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      <Paper sx={{ padding: 2 }}>
        {/* <Typography component="h1" variant="h6" align="center" marginY={1}>
          {result?.data?.details?.ext_1}
        </Typography> */}
        {result.isLoading ? (
          <Loader />
        ) : (
          <Typography
            dangerouslySetInnerHTML={{
              __html: result?.data?.details?.contents,
            }}
          ></Typography>
        )}
      </Paper>
    </Box>
  );
};

export default About;
