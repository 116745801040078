import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LOGO } from "../../assets";
import ButtonSubmit from "../Buttons/ButtonSubmit";

interface AlcoholicBeveragesProps {
  handleSubmit: () => void;
}

const AlcoholicBeverages = ({ handleSubmit }: AlcoholicBeveragesProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);

  const back = () => {
    navigate(-1);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          bgcolor: "#9f1831",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            paddingX: "10px",
            paddingY: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingY: "20px",
            }}
          >
            <div>
              <img alt="logo" src={LOGO} width={125} height={125} />
            </div>
          </Box>

          <Paper sx={{ width: 1, padding: 2 }} elevation={12}>
            <Typography
              sx={{ paddingY: 2.5, fontWeight: 550 }}
              component="h1"
              variant="h6"
              align="center"
            >
              ご利用にあたって
            </Typography>

            <Typography
              align="center"
              sx={{
                fontSize: "0.8rem",
                paddingBottom: 5,
                width: 0.8,
                marginX: "auto",
              }}
            >
              未成年の飲酒は法学で禁じられています
              飲酒運転は法律で禁止されています
              妊娠中や授乳期の飲酒は、脂児・乳児の発育に
              悪影響を与える恐れがあります
            </Typography>

            <Typography
              align="center"
              sx={{
                fontSize: "0.8rem",
                width: 0.6,
                marginX: "auto",
                paddingBottom: 1,
              }}
            >
              上記内容を理解し、了承します また、私は20歳以上です
            </Typography>

            <ButtonSubmit
              type="submit"
              sx={{ my: 1, width: 1, fontWeight: 600 }}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              はい
            </ButtonSubmit>

            <Button
              type="button"
              onClick={() => back()}
              sx={{
                my: 1,
                width: 1,
                color: "#000",
                fontWeight: 600,
                borderColor: "#000",
              }}
              variant="outlined"
            >
              前のページに戻る
            </Button>
          </Paper>
        </Box>
      </Container>
    </Modal>
  );
};

export default AlcoholicBeverages;
