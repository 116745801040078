import { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import ButtonBack from "../../components/Buttons/ButtonBack";
import { usePaymentApp } from "../../custom-hooks/apis/use-payment-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import PaymentFailed from "../PaymentFailed";
import PaymentDone from "../PaymentDone";
import PaymentError from "../../components/PaymentError";

const PaymentMethod = () => {
  const [open, setOpen] = useState<boolean>(true);
  const urlParams = new URLSearchParams(window.location.search);
  const link_id = urlParams.get("link_id");
  const navigate = useNavigate();

  const { getRedirectPayment } = usePaymentApp();
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.redirectPayment, link_id],
    () => getRedirectPayment({ payment_id: link_id })
  );

  if (!link_id || isError) return <PaymentFailed />;
  if (isLoading) return <Loader />;
  // if (data?.error) return <PaymentError message={data?.body?.errorMessage} />;
  if (data?.paymented_flag) return <PaymentDone />;

  const handleBackUrl = () => {
    window.location.href = data?.return_url;
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          height: `100vh`,
          bgcolor: "#E6E4EB",
          paddingX: 0,
        }}
      >
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
        >
          <Toolbar
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "center",
              paddingX: 0,
            }}
          >
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                bgcolor: "#fff",
                paddingY: 0.5,
                paddingX: 5,
                borderRadius: 15,
                fontSize: "12pt",
              }}
            >
              決済方法
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper sx={{ marginX: "16px" }}>
          <MenuList dense>
            <MenuItem
              sx={{ paddingY: "10px" }}
              onClick={() =>
                navigate(
                  "/payment?link_id=" +
                    link_id +
                    "&paymented_flag=" +
                    data?.paymented_flag +
                    "&redirect_url=" +
                    data?.redirect_url
                )
              }
            >
              <ListItemText>
                <Typography>クレジットカード</Typography>
              </ListItemText>
              <ListItemIcon sx={{ justifyContent: "end" }}>
                <ArrowForwardIos
                  sx={{
                    height: "0.8em",
                    width: "0.8em",
                  }}
                />
              </ListItemIcon>
            </MenuItem>
          </MenuList>

          <Box
            position="absolute"
            sx={{ bottom: 0, width: "90%", paddingY: 3 }}
          >
            <Box display="flex" justifyContent="center">
              <ButtonBack
                variant="contained"
                sx={{ width: "90%" }}
                onClick={() => handleBackUrl()}
              >
                決済を中断してアプリに戻る
              </ButtonBack>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Modal>
  );
};

export default PaymentMethod;
