import { useCallback, useContext, useMemo } from "react";
import appHistoryApi from "../../apis/history-api";
import GlobalDataContext from "../use-global-data";

export const useHistoryApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const historyApi = useMemo(
    () =>
      new appHistoryApi(
        process.env.REACT_APP_API_4 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const alcoholApi = useMemo(
    () =>
      new appHistoryApi(
        process.env.REACT_APP_API_5 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getHistory = useCallback(async () => {
    loadingOp.start();
    const history = await historyApi.getHistory();
    loadingOp.finish();
    return history;
  }, [historyApi]);

  const getHistoryDetail = useCallback(
    async (post_id: string) => {
      loadingOp.start();
      const historyDetail = await historyApi.getHistoryDetail(post_id);
      loadingOp.finish();
      return historyDetail;
    },
    [historyApi]
  );

  const getAlcoholDetail = useCallback(
    async (sake_id: string) => {
      loadingOp.start();
      const alcoholDetail = await alcoholApi.getAlcoholDetail(sake_id);
      loadingOp.finish();
      return alcoholDetail;
    },
    [alcoholApi]
  );

  return { getHistory, getHistoryDetail, getAlcoholDetail };
};
