import { useEffect, useContext, useState } from "react";
import { useAccountApi } from "./custom-hooks/apis/use-account-api";
import { QueryKeys } from "./global-state/react-query-keys";
import { useQuery } from "react-query";
import AllRoutes from "./routes";
import AuthContext from "./custom-hooks/use-auth-context";
import AlcoholicBeverages from "./components/Popup/AlcoholicBeverages";
import moment from "moment";
import "moment/locale/ja";
import GlobalDataContext from "./custom-hooks/use-global-data";
import SocialRegisterationForm from "./components/Auth/SocialRegisterationForm";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Loader from "./components/Loader";

export default function App() {
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState<boolean>(false);
  const [socialRegistration, setSocialRegistration] = useState<boolean>(true);
  const { tab } = useContext(GlobalDataContext);
  const navigate = useNavigate();

  const { getAccountActivate } = useAccountApi();

  const { isLoading } = useQuery(
    [QueryKeys.accountActivate, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getAccountActivate();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    authCtx.assessLoggedInState();

    const raw_init = localStorage.getItem("alcholic_agreement");
    const init = raw_init ? JSON.parse(raw_init) : false;
    if (!init) setOpen(true);

    moment.locale("ja");

    // social login logic
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          authCtx.loginLoadingOp.start();

          const triggerSignIn = async () => {
            authCtx.loginLoadingOp.start();
            try {
              const userData = await Auth.currentAuthenticatedUser();
              authCtx.onSocialLogin(userData);
              // Do something with the data
              const social_redirect_url = sessionStorage.getItem(
                "social_redirect_url"
              );
              if (social_redirect_url) {
                sessionStorage.removeItem("social_redirect_url");
                authCtx.loginLoadingOp.finish();

                const BASE_URL =
                  window.location.protocol + "//" + window.location.host;
                const red_url = social_redirect_url.replace(BASE_URL, "");
                navigate(red_url);
                // window.location.href = social_redirect_url;
              }
            } catch (error) {
              // console.log(
              //   "🚀 ~ file: App.tsx:72 ~ triggerSignIn ~ error:",
              //   error
              // );
            } finally {
              authCtx.loginLoadingOp.finish();
            }
          };
          triggerSignIn();
          break;
      }
    });
    return unsubscribe;
  }, []);

  const hidePopup = () => {
    setOpen(false);
    localStorage.setItem("alcholic_agreement", JSON.stringify(true));
  };

  if (isLoading) return <Loader />;

  if (
    authCtx.user?.provider !== "custom" &&
    authCtx.account?.initial_access === "true" &&
    socialRegistration
  )
    return (
      <SocialRegisterationForm
        handleSubmit={() => setSocialRegistration(false)}
      />
    );

  if (open) return <AlcoholicBeverages handleSubmit={hidePopup} />;
  return <AllRoutes />;
}
