import { useCallback, useContext, useMemo } from "react";
import appCommonApi from "../../apis/common-api";
import GlobalDataContext from "../use-global-data";

export const useCommonApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);
  const commonApi = useMemo(() => new appCommonApi(), []);

  const getTopPageInfo = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getTopPageInfo(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  const getTermsOfUseInfo = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getTermsOfUseInfo(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  const getCommerce = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getCommerce(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  const getAboutCompanyInfo = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getAboutCompanyInfo(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  const getPrivacyPolicyInfo = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getPrivacyPolicyInfo(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  const postContact = useCallback(
    async (params: any, cb: () => void) => {
      loadingOp.start();
      const response = await commonApi.postContact(params);
      cb();
      loadingOp.finish();
      return response;
    },
    [commonApi]
  );

  const getAppInfo = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getAppInfo(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  const getManualInfo = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const data = await commonApi.getManualInfo(topics_id);
      loadingOp.finish();
      return data;
    },
    [commonApi]
  );

  return {
    getTopPageInfo,
    getTermsOfUseInfo,
    getCommerce,
    getAboutCompanyInfo,
    getPrivacyPolicyInfo,
    postContact,
    getAppInfo,
    getManualInfo,
  };
};
