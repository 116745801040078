import { BrowserQRCodeReader } from "@zxing/browser";
import React, { FC, useEffect, useRef, useState } from "react";
import Result from "@zxing/library/esm/core/Result";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography/Typography";
import { Box, IconButton, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type QrCodeReaderProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRead: (result: Result) => void;
  onError: (error: any) => void;
  title: string;
};

type CameraDeviceInfo = {
  id: string;
  name: string;
};

export const QrCodeReader: FC<QrCodeReaderProps> = ({
  title,
  onRead,
  onError,
  setOpen,
}) => {
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mountedRef = useRef<boolean>(false);
  const [devices, setDevices] = useState<CameraDeviceInfo[]>([]);
  const [currentCamera, setCurrentCamera] = useState<string | undefined>(
    undefined
  );

  const setDevicesList = async (): Promise<CameraDeviceInfo[]> => {
    const list = await BrowserQRCodeReader.listVideoInputDevices();
    const result: CameraDeviceInfo[] = [];
    for (const device of list) {
      result.push({ id: device.deviceId, name: device.label });
    }
    setDevices([...result]);
    return result;
  };

  useEffect(() => {
    mountedRef.current = true;
    const codeReader = new BrowserQRCodeReader(undefined, undefined);
    setDevicesList();
    codeReader
      .decodeFromVideoDevice(
        currentCamera,
        videoRef.current!,
        function (result, _, controls) {
          if (mountedRef.current === false) {
            controls.stop();
            return;
          }
          if (typeof result !== "undefined") {
            controls.stop();
            onRead(result);
          }
        }
      )
      .catch((error: Error) => {
        console.error(error?.message);
        onError(error.message);
      });
    return function cleanup() {
      mountedRef.current = false;
      videoRef.current = null;
    };
  }, [currentCamera]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: 1, zIndex: 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="h6"
            component="h1"
            sx={{ flexGrow: 1, fontWeight: 550, color: "#fff" }}
          >
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </Box>

      <video
        autoPlay={true}
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          minWidth: "100%",
          minHeight: "100%",
        }}
        ref={videoRef}
      />
    </div>
  );
};
