import { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { AppBar, Box, Tabs, Tab, Typography } from "@mui/material";

import LoginForm from "../../components/Auth/LoginForm";
import RegisterForm from "../../components/Auth/RegisterForm";
import { Amplify } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";
import { awsExports } from "./aws-exports";

const [localRedirectSignIn, productionRedirectSignIn] =
  awsExports.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsExports.oauth.redirectSignOut.split(",");

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(awsExports);

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AuthorizationPage() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    // if (authCtx.isLoggedIn) navigate("/");
  }, [authCtx.isLoggedIn]);

  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTab(index);
  };

  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{ bgcolor: "#9f1831", border: 0 }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#fff" },
            "& .MuiTab-root": { color: "#fff", bgcolor: "#9f1831" },
            "& .Mui-selected": {
              color: "#000",
              bgcolor: "#fff",
            },
          }}
        >
          <Tab
            style={{ opacity: 1, fontWeight: 600 }}
            label="ログイン"
            {...a11yProps(0)}
          />
          <Tab
            style={{ opacity: 1, fontWeight: 600 }}
            label="会員登録"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0} dir={theme.direction}>
        <LoginForm />
      </TabPanel>
      <TabPanel value={tab} index={1} dir={theme.direction}>
        <RegisterForm />
      </TabPanel>
    </>
  );
}
