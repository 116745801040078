import { useContext } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { Auth } from "aws-amplify";
import { useAccountApi } from "../../custom-hooks/apis/use-account-api";

const AccountUnsubscribe = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { unsubscribeAccount } = useAccountApi();

  const handleLogout = async () => {
    const response = await unsubscribeAccount();
    if (response?.message) {
      authCtx.onLogout();
      Auth.signOut();
      localStorage.removeItem("idToken");
      navigate("/complete-logout");
    } else if (response?.error) {
      navigate("/failed-logout");
    }
  };

  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      <Paper
        sx={{
          paddingY: 3,
          paddingX: 2,
          maxHeight: "calc(100vh - 160px)",
          overflowY: "scroll",
        }}
      >
        <Grid sx={{ marginBottom: 2 }}>
          <Typography
            variant="h6"
            component="h2"
            align="center"
            sx={{ flexGrow: 1, color: "#4F4F62" }}
          >
            退会する前に
          </Typography>
        </Grid>

        <Typography sx={{ paddingY: 1, fontSize: "0.8rem" }}>
          退会すると、購入したチケットの情報も削除され、もとに戻すことはできません。
        </Typography>

        <Grid display="flex" justifyContent="center" marginY={2}>
          <ButtonSubmit
            onClick={() => navigate("/ticket")}
            type="button"
            sx={{ width: 1 }}
          >
            チケットを確認する
          </ButtonSubmit>
        </Grid>

        <Typography sx={{ paddingY: 1, fontSize: "0.8rem" }}>
          退会を希望される場合は、以下の「退会する」ボタンをタップしてください。
        </Typography>

        <Typography sx={{ paddingY: 1, fontSize: "0.8rem" }}>
          退会すると、個人情報は削除されます。ただし飲んだ履歴や日本酒に関する情報は、個人情報を除いた上でデータとして残ります。予めご了承ください。
        </Typography>

        <Typography sx={{ paddingY: 1, fontSize: "0.8rem" }}>
          残ったデータは利用規約に則り、本システムで利活用されます。
        </Typography>

        <Typography sx={{ paddingY: 1, fontSize: "0.8rem" }}>
          詳しくは
          <Link to="/terms-of-use" style={{ color: "#26CED5" }}>
            利用規約
          </Link>
          を御覧ください。
        </Typography>

        <Typography align="center" sx={{ paddingY: 3 }}>
          本当に退会しますか？
        </Typography>

        <Grid sx={{ marginBottom: 2 }}>
          <Button
            type="button"
            sx={{
              width: 1,
              backgroundColor: "#E5E5E5",
              color: "#000",
              borderColor: "#C6C1C1",
            }}
            onClick={handleLogout}
          >
            退会する
          </Button>
        </Grid>
        <Grid sx={{ marginBottom: 2 }}>
          <ButtonSubmit
            onClick={() => navigate("/")}
            type="button"
            sx={{ width: 1 }}
          >
            退会しない
          </ButtonSubmit>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AccountUnsubscribe;
