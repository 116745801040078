import React from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const ImportTicketFailure = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: `calc(100vh - 90px)`,
      }}
    >
      <Grid>
        <Grid my={2}>
          <Typography
            sx={{
              width: 1,
              fontWeight: 550,
              fontSize: "16px",
            }}
            align="center"
          >
            取り込みに失敗しました
          </Typography>
        </Grid>
        <Grid my={2} display="flex" justifyContent="center">
          <Typography
            sx={{
              width: 0.9,
              fontSize: "12px",
            }}
            align="center"
          >
            正常にチケットを取り込むことができませんでした。再度取り込みをお試しいただくか、管理者に問い合わせてください。
          </Typography>
        </Grid>
        <Grid my={2} display="flex" justifyContent="center">
          <ButtonSubmit
            variant="contained"
            onClick={() => navigate(`/ticket-import`, { state: state })}
          >
            チケット取り込み確認に戻る
          </ButtonSubmit>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImportTicketFailure;
