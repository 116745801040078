import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const VerificationEmail = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
      }}
    >
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{ width: 1, display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" component="h1">
            メールアドレスの認証
          </Typography>
          <Box sx={{ width: "48px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      <Paper sx={{ padding: 2.5 }}>
        <Box>
          <Typography sx={{ paddingBottom: 4 }}>
            認証が完了しました。
          </Typography>
          <Button
            type="button"
            onClick={() => navigate("/settings")}
            sx={{
              width: 1,
              color: "#000000",
              fontWeight: 600,
              borderColor: "#000000",
            }}
            variant="outlined"
          >
            設定画面に戻る
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default VerificationEmail;
