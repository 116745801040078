import { Fragment, memo } from "reactn";
import { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useNotificationApi } from "../../custom-hooks/apis/use-notification-api";
import { ArrowForwardIos } from "@mui/icons-material";
import Loader from "../../components/Loader";
import moment from "moment";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

function removeTags(input: string): string {
  return input.replace(/<[^>]*>/g, '');
}

const Notification = () => {
  const navigate = useNavigate();
  const { getNotifications } = useNotificationApi();

  const result = useQuery([QueryKeys.notifications], () => getNotifications());
  // if (result.error) return <h4>Error...</h4>;

  const styles = {
    icon: {
      width: "0.8em",
      height: "0.8em",
    },
  };

  return result.isLoading ? (
    <Loader />
  ) : result?.data?.list && result?.data?.list.length > 0 ? (
    <Box sx={{ paddingX: 2 }}>
      <Grid item>
        <Demo sx={{ borderRadius: "5px" }}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: "0px 3px 5px rgb(0 0 0 / 10%)",
              borderRadius: "5px",
              marginBottom: "50px",
            }}
          >
            {result?.data?.list.map((notification: any, index: number) => {
              return (
                <Fragment key={"notification" + index}>
                  {index > 0 && <Divider />}
                  <ListItem
                    alignItems="center"
                    key={"notification" + index}
                    onClick={() =>
                      navigate("/notifications/" + notification?.topics_id, {
                        state: { topics_id: notification?.topics_id },
                      })
                    }
                  >
                    <ListItemText
                      primary={
                        <Fragment>
                          <Typography fontSize="10pt" mb={1}>
                            {moment(notification?.inst_ymdhi).format(
                              "YYYY/MM/DD HH:mm"
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              display: "inline",
                              fontWeight: 550,
                              fontSize: "18px",
                            }}
                            component="h1"
                            variant="h6"
                          >
                            <Box lineHeight={"19px"} mb={1} >
                              {notification?.subject}
                            </Box>
                          </Typography>
                        </Fragment>
                      }
                      secondary={
                        <Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                            fontSize="12px"
                          >
                            <Box lineHeight={"15px"} >
                              {removeTags(notification?.ext_3).substring(0, 40) + "..."}
                            </Box>
                          </Typography>
                        </Fragment>
                      }
                    />
                    <ListItemIcon sx={{ justifyContent: "end" }}>
                      <ArrowForwardIos
                        sx={{
                          height: "0.8em",
                          width: "0.8em",
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Demo>
      </Grid>
    </Box>
  ) : (
    <Box sx={{ paddingX: 2 }}>
      <Paper
        sx={{
          display: "flex",
          minHeight: `calc(100vh - 200px)`,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        お知らせはありません
      </Paper>
    </Box>
  );
};

export default memo(Notification);
