import { useCallback, useContext, useMemo } from "react";
import appNotificationApi from "../../apis/notification-api";
import GlobalDataContext from "../use-global-data";

export const useNotificationApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);
  const notificationApi = useMemo(() => new appNotificationApi(), []);

  const getNotifications = useCallback(async () => {
    loadingOp.start();
    const notifications = await notificationApi.getNotifications();
    loadingOp.finish();
    return notifications;
  }, [notificationApi]);

  const getNotification = useCallback(
    async (topics_id: number) => {
      loadingOp.start();
      const notification = await notificationApi.getNotification(topics_id);
      loadingOp.finish();
      return notification;
    },
    [notificationApi]
  );

  return {
    getNotification,
    getNotifications,
  };
};
