import BaseApi from "./base-api";

export default class appNotificationApi extends BaseApi {
  async getNotifications(): Promise<any> {
    return (await this.get("rcms-api/3/news")).data;
  }

  async getNotification(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/news/" + topics_id)).data;
  }
}
