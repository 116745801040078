import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../components/Loader";

const CheckoutForm = (props: any) => {
  const { clientSecret, setAmount, redirect_url } = props;
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (stripe) {
      stripe.retrievePaymentIntent(clientSecret).then(function (result) {
        setAmount(result?.paymentIntent?.amount);
      });
    }
  }, [stripe, clientSecret]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const BASE_URL = window.location.protocol + "//" + window.location.host;

    const { error } = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          BASE_URL + "/payment-completed?redirect_url=" + redirect_url,
        payment_method_data: {
          billing_details: {
            address: {
              country: "JP",
            },
          },
        },
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error?.message || "");
    } else {
      setMessage("予期せぬエラーが発生しました。");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {message && (
        <Box
          sx={{
            width: 1,
            paddingY: 1.5,
            paddingX: 2.5,
            border: 1,
            color: "#df1b41",
            borderColor: "#df1b41",
            borderBlockColor: "#df1b41",
            margin: "15px 0",
          }}
        >
          <Typography>{message}</Typography>
        </Box>
      )}
      <PaymentElement
        id="payment-element"
        className="payment-element-container"
        onLoaderStart={(event: any) => {
          setIsProcessing(true);
        }}
        onReady={() => {
          setIsProcessing(false);
        }}
        onLoadError={(event: any) => {
          setMessage(event?.error?.message || "");
        }}
        options={{
          fields: {
            billingDetails: {
              address: {
                country: "never",
              },
            },
          },
        }}
      />

      <Box marginX="16px">
        <Box>
          <Grid paddingY={1}>
            <Typography sx={{ fontWeight: 550, marginY: 1 }}>
              ご利用上の注意
            </Typography>
            <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
              他の店舗ではご利用いただけません。
            </Typography>
            <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
              換金や払い戻しはできません。
            </Typography>
            <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
              商品品切れの際にはご容赦ください。
            </Typography>
            <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
              支払時期、返金・交換についての詳細はこちらをご確認ください。
            </Typography>
            <Typography
              sx={{ color: "#4169e1", fontSize: "0.8rem" }}
              onClick={() => navigate("/commerce")}
            >
              <u>特定商取引法に基づく表記</u>
            </Typography>
          </Grid>
        </Box>
      </Box>

      <ButtonSubmit
        type="submit"
        sx={{ width: 1, marginY: 2 }}
        disabled={isProcessing || isLoading || !stripe || !elements}
      >
        支払う
      </ButtonSubmit>
      {isLoading && <Loader />}
      {/* Show any error or success messages */}
    </form>
  );
};

export default CheckoutForm;
