import AwsApi from "./aws-api";

export default class appAccountApi extends AwsApi {
  async getAccount(): Promise<any> {
    return await this.get("account/" + process.env.REACT_APP_ENVNAME);
  }

  async postAccount(params: any): Promise<any> {
    return await this.post("account/" + process.env.REACT_APP_ENVNAME, params);
  }

  async getAccountActivate(): Promise<any> {
    return await this.get("account/activate/" + process.env.REACT_APP_ENVNAME);
  }

  async unsubscribeAccount(params: any): Promise<any> {
    return (
      await this.delete("account/delete/" + process.env.REACT_APP_ENVNAME, {
        params,
      })
    ).data;
  }
}
