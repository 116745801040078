import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./index.css";

const TicketCard = (props: any) => {
  const { item } = props;
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [text, setText] = useState<string>("利用可能");

  const start_date = moment
    .unix(item?.eka?.eka_lifespan_start)
    .format("YYYY/MM/DD HH:mm");

  const end_date = moment
    .unix(item?.eka?.eka_lifespan_end)
    .format("YYYY/MM/DD HH:mm");

  useEffect(() => {
    // console.log("🚀 ~ file: TicketCard ~ item:", item);
    // console.log("🚀 ~ file: TicketCard ~ start_date:", start_date);
    // console.log("🚀 ~ file: TicketCard ~ end_date:", end_date);
    if (item.ticket.type === 0) {
      if (item?.eka?.coupon === 0) {
        setDisabled(true);
        setText("使用済み");
        // console.log("condition 1");
      }
    }
    if (!item?.eka?.is_valid) {
      setDisabled(true);
      setText("使用不可");
      // console.log("condition 2");
    }
    if (
      moment(new Date()).isBefore(start_date, "seconds") &&
      item?.ticket?.lifespan_type !== 3
    ) {
      setDisabled(true);
      setText("開始前");
      // console.log("condition 3");
    }
    if (
      moment(new Date()).isAfter(end_date, "seconds") &&
      item?.ticket?.lifespan_type !== 3
    ) {
      setDisabled(true);
      setText("期限切れ");
      // console.log("condition 4");
    }
    // console.log("passed");
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disabled)
      navigate(
        "/ticket/qr-generate/" +
          item?.eka?.eka_id +
          "?type=" +
          item?.ticket?.type +
          "&span_type=" +
          item?.ticket?.lifespan_type +
          "&title=" +
          item?.ticket?.name +
          "&qty=" +
          item?.eka?.coupon +
          "&end_date=" +
          end_date +
          "&balance=" +
          item?.eka?.balance
      );
  };

  return (
    <Box paddingTop={2} onClick={handleClick}>
      {(item.ticket.type === 0 || item.ticket.type === 2) && (
        <Grid container>
          <Grid
            xs={9}
            sx={{
              backgroundColor: disabled ? "#707070" : "#fff",
              borderRadius: "8px",
              paddingTop: "20px",
              paddingBottom: "16px",
              borderRight: "0.12em dashed #c9c9c9",
              boxShadow: disabled
                ? "none"
                : "0px 5px 4px -4px rgb(0 0 0 / 30%)",
            }}
          >
            <Grid sx={{ paddingX: 2 }}>
              <Typography
                sx={{
                  fontSize: "9px",
                  color: "#000",
                }}
              >
                {item?.store?.name} {item?.store?.subname}{" "}
              </Typography>
            </Grid>
            <Grid container sx={{ paddingX: "18px" }}>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    fontSize: "17px",
                    paddingLeft: "5px",
                    fontWeight: 900,
                  }}
                >
                  {item?.ticket?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ paddingX: 2 }}>
              <Typography className="expiration_date">
                有効期限：
                {item?.ticket?.lifespan_type === 0 ? (
                  <>{item?.ticket?.lifespan_unit} </>
                ) : item?.ticket?.lifespan_type === 3 ? (
                  <>無制限</>
                ) : (
                  <>{start_date}~</>
                )}
                {item?.ticket?.lifespan_type === 3 ? (
                  <></>
                ) : (
                  <>{end_date}まで</>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={3}
            padding={0}
            sx={{
              backgroundColor: disabled
                ? "#E6E4EB"
                : item.ticket.type === 2
                ? "#5159BC"
                : "#BC5151",
              color: "#fff",
              borderRadius: "8px",
              boxShadow: disabled
                ? "none"
                : "0px 5px 4px -4px rgb(0 0 0 / 30%)",
            }}
          >
            <Box sx={{ height: 1 }}>
              <Grid
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ position: "relative", height: 1 }}
              >
                <Typography
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: disabled ? "#7E7E7E" : "#fff",
                  }}
                >
                  {text}
                </Typography>

                {!disabled && (
                  <Box
                    marginTop={1}
                    sx={{
                      bottom: 0,
                      position: "absolute",
                      paddingBottom: "16px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "block",
                        fontSize: "8px",
                        color: "#fff",
                      }}
                      align="center"
                    >
                      {item.ticket.type === 2 ? "残り残高" : "残り杯数"}
                    </Typography>
                    <Typography
                      sx={{
                        display: "block",
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: 600,
                      }}
                      align="center"
                    >
                      {item.ticket.type === 2
                        ? item.eka?.balance + "円"
                        : item?.eka?.coupon + "杯"}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {item.ticket.type === 1 && (
        <Grid container>
          <Grid
            xs={9}
            sx={{
              paddingTop: "20px",
              borderRadius: "8px",
              paddingBottom: "16px",
              borderRight: "0.12em dashed #c9c9c9",
              backgroundColor: disabled ? "#707070" : "#fff",
              boxShadow: disabled
                ? "none"
                : "0px 5px 4px -4px rgb(0 0 0 / 30%)",
            }}
          >
            <Grid sx={{ paddingX: 2 }}>
              <Typography
                sx={{
                  fontSize: "9px",
                  color: "#000",
                }}
              >
                {item?.store?.name} {item?.store?.subname}{" "}
              </Typography>
            </Grid>
            <Grid container sx={{ paddingX: "18px" }}>
              {/* <Typography sx={{ fontSize: "45px" }}>
                {item?.ticket?.name.substring(0, 1)}
              </Typography> */}
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "17px",
                    paddingY: 2.5,
                    paddingLeft: "5px",
                    fontWeight: 600,
                  }}
                >
                  {item?.ticket?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ paddingX: 2 }}>
              <Typography sx={{ fontSize: "6px" }}>
                有効期限：
                {item?.ticket?.lifespan_type === 0 ? (
                  <>{item?.ticket?.lifespan_unit} </>
                ) : item?.ticket?.lifespan_type === 3 ? (
                  <>無制限</>
                ) : (
                  <>{start_date}~</>
                )}
                {item?.ticket?.lifespan_type === 3 ? (
                  <></>
                ) : (
                  <>{end_date}まで</>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={3}
            padding={0}
            sx={{
              color: "#fff",
              borderRadius: "8px",
              boxShadow: disabled
                ? "none"
                : "0px 5px 4px -4px rgb(0 0 0 / 30%)",
              backgroundColor: disabled ? "#E6E4EB" : "#92923F",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: disabled ? "#7E7E7E" : "#fff",
                  fontWeight: 600,
                }}
              >
                {text}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TicketCard;
