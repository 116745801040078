import { useCallback, useContext, useMemo } from "react";
import appPaymentApi from "../../apis/payment-api";
import GlobalDataContext from "../use-global-data";

export const usePaymentApp = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const paymentApi = useMemo(
    () =>
      new appPaymentApi(
        process.env.REACT_APP_API_7 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const paymentApi2 = useMemo(
    () =>
      new appPaymentApi(
        process.env.REACT_APP_API_8 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const paymentApi3 = useMemo(
    () =>
      new appPaymentApi(
        process.env.REACT_APP_API_14 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const paymentApi4 = useMemo(
    () =>
      new appPaymentApi(
        process.env.REACT_APP_API_15 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getRedirectPayment = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await paymentApi.getRedirectPayment(params);
      loadingOp.finish();
      return response;
    },
    [paymentApi]
  );

  const createPayment = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await paymentApi2.createPayment(params);
      loadingOp.finish();
      return response;
    },
    [paymentApi2]
  );

  const payQuick = useCallback(
    async (params: any) => {
      loadingOp.start();
      try {
        const response = await paymentApi3.payQuick(params);
        return response;
      } catch (e: any) {
        // console.log("🚀 ~ file: use-payment-api.tsx:78 ~ e:", e);
        return { error: true, body: e?.response?.data };
      } finally {
        loadingOp.finish();
      }
    },
    [paymentApi3]
  );

  const getCardInformation = useCallback(async () => {
    loadingOp.start();
    const response = await paymentApi4.getCardInformation();
    loadingOp.finish();
    return response;
  }, [paymentApi4]);

  const getClientSecret = useCallback(async () => {
    loadingOp.start();
    const response = await paymentApi4.getClientSecret();
    loadingOp.finish();
    return response;
  }, [paymentApi4]);
  return {
    getRedirectPayment,
    createPayment,
    payQuick,
    getCardInformation,
    getClientSecret,
  };
};
