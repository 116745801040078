import { useEffect, useState } from "react";
import { Box, Container, Grid, Modal, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const PaymentCompleted = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [redirectUrl, setRedirectUrl] = useState<string | null>("");
  const { state } = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const redirect_url = urlParams.get("redirect_url");

  useEffect(() => {
    if (state?.redirect_url) setRedirectUrl(state?.redirect_url);
    else if (redirect_url) setRedirectUrl(redirect_url);
  }, [state?.redirect_url, redirect_url]);

  const backApp = () => {
    if (!redirectUrl) return;
    window.location.href = redirectUrl;
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            height: "100vh",
            bgcolor: "#E6E4EB",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Grid
              sx={{
                paddingY: 3,
              }}
            >
              <Typography
                sx={{
                  width: 1,
                  fontWeight: 550,
                  fontSize: "18pt",
                }}
                align="center"
              >
                決済が完了しました
              </Typography>
            </Grid>

            <Grid
              sx={{
                paddingY: 1,
              }}
            >
              <Typography sx={{ width: 1, fontSize: "12pt" }} align="center">
                のまっせをご利用いただきありがとうございます
              </Typography>
              <Typography sx={{ width: 1, fontSize: "12pt" }} align="center">
                引き続き新しいSAKE体験をお楽しみください
              </Typography>
            </Grid>
            <Grid
              sx={{
                paddingY: 1,
              }}
            >
              <Typography sx={{ width: 1, fontSize: "10pt" }} align="center">
                ※チケットの反映に数分かかる場合があります
              </Typography>
            </Grid>

            <Grid
              sx={{
                paddingY: 3,
              }}
            >
              {redirectUrl ? (
                <ButtonSubmit fullWidth onClick={() => backApp()}>
                  アプリケーションに戻る
                </ButtonSubmit>
              ) : (
                <>
                  <Typography
                    sx={{ width: 1, fontSize: "12pt", color: "red" }}
                    align="center"
                  >
                    リダイレクト先の情報取得に失敗しました
                  </Typography>
                  <Typography
                    sx={{ width: 1, fontSize: "12pt", color: "red" }}
                    align="center"
                  >
                    手動でアプリケーションに戻ってください
                  </Typography>
                </>
              )}
            </Grid>
          </Box>
        </Container>
      </>
    </Modal>
  );
};

export default PaymentCompleted;
