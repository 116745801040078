import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useQuery } from "react-query";
import { useTicketApp } from "../../custom-hooks/apis/use-ticket-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import moment from "moment";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const WS_URL = process.env.REACT_APP_SOCKET_URL || "";

const QrCodeGenerate = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { eka_id } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  let state_type = parseInt(urlParams.get("type") || "0");
  let state_span_type = parseInt(urlParams.get("span_type") || "0");
  let state_title = urlParams.get("title");
  let state_qty = parseInt(urlParams.get("qty") || "0");
  let state_balance = parseInt(urlParams.get("balance") || "0");
  let state_end_date = urlParams.get("end_date");

  console.log(
    "🚀 ~ file: index.tsx:30 ~ QrCodeGenerate ~ urlParams:",
    urlParams
  );

  const [qty, setQty] = useState<number>(
    state_type == 0 ? state_qty : state_balance
  );
  //const [balance, setBalance] = useState<number>(state_balance);
  const [endDate, setEndDate] = useState<string>(state_end_date || "");
  const refContainer: RefObject<HTMLDivElement> = useRef(null);
  const { generateQrCode } = useTicketApp();

  const { data, isLoading } = useQuery(
    [QueryKeys.generateQrCode, eka_id, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return generateQrCode({
          eka_id: eka_id,
        });

      return;
    }
  );

  const [socket, setSocket] = useState<WebSocket>();
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const loadSVG = (container: HTMLDivElement, base64: string) => {
    var dataURL = "data:image/svg+xml;base64," + base64;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", dataURL);
    xhr.addEventListener("load", function (ev: any) {
      var xml = ev.target.response;
      var dom = new DOMParser();
      var svg = dom.parseFromString(xml, "image/svg+xml");
      svg.documentElement?.setAttribute("height", "220px");
      svg.documentElement?.setAttribute("width", "220px");
      if (container.children.length === 0)
        container.appendChild(svg.documentElement);
    });
    xhr.send(null);
  };

  useEffect(() => {
    if (data?.encodeImage && refContainer.current) {
      loadSVG(refContainer.current, data.encodeImage);
    }
  }, [data?.encodeImage, refContainer?.current]);

  const today = moment();

  useEffect(() => {
    if (state_type === 1) {
      const intervalId = setInterval(() => {
        const today = moment().format("YYYY/MM/DD HH:mm");
        const isDayAfterToday = moment(today).isAfter(endDate);

        if (isDayAfterToday) setIsExpired(isDayAfterToday);
      }, 60000); // check every minute

      return () => clearInterval(intervalId);
    }
  }, [today, endDate]);

  useEffect(() => {
    setSocket(new WebSocket(WS_URL));
    if (state_span_type === 3) {
      setIsExpired(false);
    } else if (state_end_date) {
      const end_date = moment(state_end_date).format("YYYY-MM-DD HH:mm");
      // const end_date = moment(1677628620000).format("YYYY-MM-DD HH:mm");
      setEndDate(end_date);

      const today = moment().format("YYYY/MM/DD HH:mm");
      const isDayAfterToday = moment(today).isAfter(end_date);

      setIsExpired(isDayAfterToday);
    }

    return () => {
      socket?.close();
    };
  }, []);

  useEffect(() => {
    socket != null &&
      socket.addEventListener("close", function () {
        // ✨✨【今回のポイント】closeイベント発火後、再度インスタンスを生成することで再接続する✨✨
        setSocket(new WebSocket(WS_URL));
      });

    socket != null &&
      socket.addEventListener("open", function () {
        // 初期化処理実行
        socket.send(
          JSON.stringify({
            eka_id: eka_id,
          })
        );
      });

    socket != null &&
      socket.addEventListener("message", function (e) {
        try {
          // console.log("🚀 ~ file: index.tsx:81 ~ socket data :", e.data);
          setQty(e.data);
          if (parseInt(e.data) === 0)
            refContainer.current?.removeChild(
              refContainer.current?.children[0]
            );
          else {
            const data = JSON.parse(e.data); // JSON.parse(end_date)
            setEndDate(
              moment.unix(data?.eka_lifespan_start).format("YYYY-MM-DD HH:mm")
            );
          }
        } catch (e) {
          // console.log("error出たよ、", e);
        }
      });
  }, [socket]);

  if (!state_title || !state_end_date)
    return (
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: `calc(100vh - 90px)`,
        }}
      >
        <Grid>
          <Grid my={2}>
            <Typography
              sx={{
                width: 1,
                fontWeight: 550,
                fontSize: "16px",
              }}
              align="center"
            >
              チケット情報が読み込めませんでした。
            </Typography>
          </Grid>
          <Grid my={2} display="flex" justifyContent="center">
            <Typography
              sx={{
                width: 0.9,
                fontSize: "12px",
              }}
              align="center"
            >
              前の画面に戻って再度読み込むか、管理者に問い合わせてください。
            </Typography>
          </Grid>
          <Grid my={2} display="flex" justifyContent="center">
            <ButtonSubmit
              variant="contained"
              sx={{ width: 0.9 }}
              onClick={() => navigate(-1)}
            >
              前の画面に戻る
            </ButtonSubmit>
          </Grid>
        </Grid>
      </Grid>
    );

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        paddingX: 0,
      }}
    >
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{ width: 1, display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() =>
              navigate("/ticket", { state: { refresh_uuid: Date.now() } })
            }
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{ color: "#4F4F62", fontWeight: 600, fontSize: "1.2rem" }}
          >
            QRコードの表示
          </Typography>
          <Box sx={{ width: "36px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Loader />
      ) : !eka_id || data?.error || !endDate || isExpired ? (
        <Box
          sx={{
            display: "flex",
            minHeight: `calc(100vh - 250px)`,
            justifyContent: "center",
            alignItems: "center",
            paddingX: "16px",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontWeight: 550, marginY: 3 }}>
            QRコードが表示できません
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", width: "0.85" }} align="center">
            このチケットは有効期限切れか、存在しないチケットの可能性があります。前の画面からやり直してください。
          </Typography>
        </Box>
      ) : (
        <Box sx={{ height: `calc(100vh - 160px)`, overflowY: "scroll" }}>
          <Paper sx={{ marginX: "16px", padding: 2 }}>
            <Grid sx={{ paddingBottom: 2 }}>
              {state_type === 0 && qty < 1 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "200px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 550, fontSize: "0.8rem" }}
                    align="center"
                  >
                    チケットの回数または有効期限が切れました
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 550, fontSize: "0.8rem" }}
                    align="center"
                  >
                    ご利用いただきありがとうございます
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "16px",
                  }}
                  ref={refContainer}
                ></Box>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Typography sx={{ fontWeight: 700 }} align="center">
                {/* 回数チケット 3杯分 */}
                {state_title}
              </Typography>
            </Grid>
            <Grid sx={{ paddingY: 1 }}>
              <Typography sx={{ fontSize: "0.7rem", color: "#7E7E7E" }}>
                表示されたORコードを「のまっせ」のリーダーにかざして
                おいしいお酒をお楽しみください。
              </Typography>
            </Grid>

            <Grid>
              <Typography sx={{ fontSize: "0.7rem", color: "#7E7E7E" }}>
                QRコードの読み取りに失敗する場合は、画面を明るくして
                再度読み取りをおねがいします。
              </Typography>
            </Grid>
          </Paper>

          <Paper sx={{ marginY: 2, paddingY: 2, marginX: "16px" }}>
            {state_type === 0 ? (
              <Typography sx={{ fontWeight: 550 }} align="center">
                残り{qty}杯
              </Typography>
            ) : state_type === 2 ? (
              <Typography sx={{ fontWeight: 550, color: "red" }} align="center">
                プリペイド残高：{qty}円
              </Typography>
            ) : state_span_type === 3 && state_type === 1 ? (
              <Typography sx={{ fontWeight: 550, color: "red" }} align="center">
                有効期限：無制限
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 550, color: "red" }} align="center">
                有効期限：{endDate}
              </Typography>
            )}
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default QrCodeGenerate;
