import { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  PaperProps,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import { usePaymentApp } from "../../custom-hooks/apis/use-payment-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useQuery } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import PaymentFailed from "../PaymentFailed";
import Loader from "../../components/Loader";
import PaymentDone from "../PaymentDone";
import PaymentError from "../../components/PaymentError";
import CheckoutForm from "../../components/CheckoutForm";
import SecondCheckoutForm from "../../components/SecondCheckoutForm";
import AuthContext from "../../custom-hooks/use-auth-context";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY || ""
);

const CustomPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  "@media (max-height: 650px)": {
    // maxHeight: "calc(100vh - 300px)",
    // backgroundColor: "green",
  },
  "@media (max-height: 700px)": {
    // maxHeight: "calc(100vh - 350px)",
    // backgroundColor: "yellow",
  },
  maxHeight: "calc(100vh)",
  // backgroundColor: "grey",
}));

const Payment = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [amount, setAmount] = useState<number>(0);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.search);
  const link_id = urlParams.get("link_id");
  const paymented_flag = urlParams.get("paymented_flag");
  const redirect_url = urlParams.get("redirect_url");

  const appearance = {
    theme: "stripe",
    rules: {
      ".Label": {
        marginBottom: "6px",
      },
    },
  } as Appearance;

  const { createPayment } = usePaymentApp();
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.createPayment, link_id],
    () =>
      createPayment({
        payment_id: link_id,
        payment_types: ["card"],
      })
  );

  if (!link_id) return <PaymentFailed />;
  if (isLoading) return <Loader />;
  if (isError) return <PaymentError />;
  if (paymented_flag === "true") return <PaymentDone />;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Container
          maxWidth="sm"
          sx={{
            height: `100vh`,
            bgcolor: "#E6E4EB",
            paddingX: 0,
          }}
        >
          <AppBar
            position="static"
            color="transparent"
            elevation={0}
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  navigate(`/payment/select?link_id=${link_id}`);
                }}
                sx={{
                  bgcolor: "#fff",
                  border: "1px solid #000",
                }}
              >
                <ArrowBackIosIcon sx={{ height: "0.8em", width: "0.8em" }} />
              </IconButton>
              <Typography
                variant="h6"
                component="h1"
                sx={{
                  color: "#4F4F62",
                  bgcolor: "#fff",
                  paddingY: 0.5,
                  paddingX: 5,
                  borderRadius: 15,
                  fontSize: "12pt",
                }}
              >
                決済
              </Typography>
              <Box sx={{ width: "31px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
          <Paper
            sx={{
              marginY: 3,
              paddingX: 2,
              paddingTop: "30px",
              marginX: "16px",
              overflowY: "scroll",
              maxHeight: `calc(100vh - 250px)`,
              paddingBottom: `calc(4.5em + env(safe-area-inset-bottom))`,
            }}
          >
            <Grid sx={{ marginBottom: 2 }}>
              <Typography
                align="center"
                sx={{ fontSize: "18px", fontWeight: 600 }}
              >
                支払い情報の入力
              </Typography>
            </Grid>
            {data?.clientSecret && (
              <Elements
                options={{
                  clientSecret: data?.clientSecret,
                  locale: "ja",
                  appearance: appearance,
                }}
                stripe={stripePromise}
              >
                {data?.isPaymentValid ? (
                  <SecondCheckoutForm
                    setAmount={setAmount}
                    clientSecret={data?.clientSecret}
                    redirect_url={redirect_url}
                    cardNumberLast4={data?.cardNumberLast4}
                  />
                ) : (
                  <CheckoutForm
                    setAmount={setAmount}
                    clientSecret={data?.clientSecret}
                    redirect_url={redirect_url}
                  />
                )}
              </Elements>
            )}
          </Paper>
          <Paper
            sx={{
              position: "absolute",
              bottom: 0,
              width: 1,
              backgroundColor: "#9F1831",
              color: "#fff",
              paddingY: 3.76,
              paddingX: 3,
              height: "130px",
            }}
            component="footer"
            square
            variant="outlined"
          >
            <Typography
              sx={{
                fontSize: "6vw",
                fontWeight: 700,
              }}
            >
              {data?.ticketName}
            </Typography>
            <Typography
              sx={{
                fontSize: "15pt",
              }}
            >
              {amount}円
            </Typography>
          </Paper>
        </Container>
      </>
    </Modal>
  );
};

export default Payment;
