import * as Yup from "yup";

const phoneNumberValidation = Yup.string()
  .test("phone-number", "無効な電話番号", (value) => {
    const phoneRegexes = {
      Mongolia: /^((\+?976)|0)[6789]\d{7}$/,
      Japan: /^((\+?81)|0)([789]0|4\d{1})\d{4}\d{4}$/,
      USA: /^((\+?1)[ -]?)?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/,
    };
    for (const [country, regex] of Object.entries(phoneRegexes)) {
      if (value && regex.test(value)) {
        return true;
      }
    }
    return false;
  })
  .required("電話番号が入力されていません。");

const emailValidation = Yup.string()
  .email("メールアドレスが無効です。")
  .required("メールアドレスが入力されていません。");

const usernameValidation = Yup.string()
  .min(1, "ニックネームは1文字以上でなければなりません。")
  .max(20, "ニックネームは20文字以内とします。")
  .required("ニックネームが入力されていません。");

const lastnameValidation = Yup.string()
  .min(1, "名字は1文字以上でお願いします")
  .max(50, "名字は50文字以内でお願いします。")
  .required("名字が入力されていません。");

const firstnameValidation = Yup.string()
  .min(1, "名前は1文字以上でお願いします")
  .max(50, "名前は50文字以内でお願いします。")
  .required("名前が入力されていません。");

const passwordValidation = Yup.string()
  .min(8, "パスワードは8文字以上でなければなりません。")
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    "パスワードは、少なくとも1つの1つの数字、1つの大文字、1つの小文字を含む必要があります。"
  )
  .required("パスワードが入力されていません。");

const currentPasswordValidation = Yup.string()
  .min(8, "現在のパスワードは8文字以上でなければなりません。")
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    "現在のパスワードは、少なくとも1つの数字、1つの大文字、1つの小文字を含む必要があります。"
  )
  .required("現在のパスワードが入力されていません。");

const newPasswordValidation = Yup.string()
  .min(8, "新しいパスワードは8文字以上でなければなりません。")
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    "新しいパスワードは、少なくとも1つの数字、1つの大文字、1つの小文字を含む必要があります。"
  )
  .required("新しいパスワードが入力されていません。");

const confirmNewPasswordValidation = Yup.string()
  .oneOf(
    [Yup.ref("new_password"), null],
    "新しいパスワードの再確認が新しいパスワードと一致しない。"
  )
  .required("新しいパスワードの再確認が入力されていません。");

const confirmPasswordValidation = Yup.string()
  .oneOf(
    [Yup.ref("password"), null],
    "新しいパスワードの再確認が新しいパスワードと一致しない。"
  )
  .required("パスワードの再確認が入力されていません。");

const genderValidation = Yup.string().required("性別が入力されていません。");

const currentAddressValidation = Yup.string().required(
  "現在お住まいの地域が入力されていません。"
);

const currentCountryValidation = Yup.string().required(
  "現在お住まいの国が入力されていません。"
);

const birthDateValidation = Yup.date()
  .nullable()
  .max(new Date(Date.now() - 631065600000), "20歳以上であること")
  .required("生年月日が入力されていません。");

const birthAddressValidation = Yup.string().required(
  "出身地（都道府県・市区町村）が入力されていません。"
);

const birthCountryValidation =
  Yup.string().required("出身地（国）が入力されていません。");

const verificationCodeValidation = Yup.string()
  .required("認証コードが必要です。")
  .length(6, "検証コードは正確に6文字である必要があります。");

const nameValidation = Yup.string()
  .min(1, "お名前は1文字以上でなければなりません。")
  .max(20, "お名前は20文字以内とします。")
  .required("お名前が入力されていません。");

const messageValidation = Yup.string()
  .min(2, "メッセージは2文字以上でなければなりません。")
  .required("メッセージが入力されていません。");

const cardNumberValidation = Yup.string()
  .required("Credit card number is required.")
  .matches(/^\d+$/, "Credit card number must be numeric.")
  .test("test-card-number", "Credit card number is invalid.", (value) => {
    if (!value) {
      return false;
    }
    let sum = 0;
    let double = false;
    for (let i = value.length - 1; i >= 0; i--) {
      let digit = parseInt(value.charAt(i), 10);
      if (double) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      double = !double;
    }
    return sum % 10 === 0;
  });

export {
  phoneNumberValidation,
  emailValidation,
  lastnameValidation,
  firstnameValidation,
  usernameValidation,
  passwordValidation,
  confirmPasswordValidation,
  genderValidation,
  birthDateValidation,
  birthAddressValidation,
  birthCountryValidation,
  currentAddressValidation,
  currentCountryValidation,
  currentPasswordValidation,
  newPasswordValidation,
  confirmNewPasswordValidation,
  verificationCodeValidation,
  nameValidation,
  messageValidation,
  cardNumberValidation,
};
