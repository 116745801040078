import { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AuthContext from "../../custom-hooks/use-auth-context";
import { useTranslation } from "react-i18next";

const VerificationEmail = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const result = await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        data.code
      );
      reset();

      if (state?.is_verification)
        authCtx.setAccountDetail({
          mail_activate: "true",
        });

      navigate("/successfully-updated-email");
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
      }}
    >
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{ width: 1, display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" component="h1">
            メールアドレスの認証
          </Typography>
          <Box sx={{ width: "48px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      <Paper sx={{ padding: 2.5 }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          メールアドレスの認証コードを{state?.email}
          に送りました。認証コードを入力してください。
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ paddingY: 1, marginTop: 2 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="code"
                    type="number"
                    placeholder="コードを入力してください"
                  />
                </FormControl>
              )}
              name="code"
              control={control}
            />
          </Grid>

          {error && (
            <Box
              sx={{
                width: 1,
                paddingY: 1,
                border: 1,
                color: "#9f1831",
                borderColor: "#9f1831",
                borderBlockColor: "#9f1831",
                margin: "20px 0 10px 0",
              }}
            >
              <Typography align="center">{t(error)}</Typography>
            </Box>
          )}

          <ButtonSubmit
            type="submit"
            sx={{ my: 3, width: 1, fontWeight: 600 }}
            variant="contained"
            disabled={loading}
          >
            確認する
          </ButtonSubmit>

          <Button
            type="button"
            onClick={() => navigate(-1)}
            sx={{
              width: 1,
              color: "#4B45E2",
              fontWeight: 600,
              borderColor: "#3B4FE6",
            }}
            variant="outlined"
          >
            再送する
          </Button>
        </form>
        {loading && <Loader />}
      </Paper>
    </Container>
  );
};

export default VerificationEmail;
