import { useState } from "react";
import { Box, FormControl, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import VerificationEmail from "./VerificationEmail";
import SuccessUpdateEmail from "./SuccessUpdateEmail";
import { Controller, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailValidation } from "../../utils/validations";

const ChangeEmail = () => {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const schema = Yup.object().shape({
    email: emailValidation,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(
        user,
        {
          email: data.email,
        },
        {
          email: data.email,
        }
      );
      setStep(1);
      setEmail(data.email);
      reset();
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      <Paper sx={{ padding: 2.5 }}>
        {step === 0 && (
          <>
            <Typography sx={{ fontSize: "0.8rem" }}>
              メールアドレスを更新する場合は、以下に新しいメールアドレスを入力してください。入力したメールアドレス宛てに認証コードが送信されます。
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid sx={{ paddingY: 1, marginTop: 2 }}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth variant="standard">
                      <BootstrapInput
                        {...field}
                        id="email"
                        type="email"
                        placeholder="メールを入力してください"
                        sx={{ width: 1 }}
                      />
                    </FormControl>
                  )}
                  name="email"
                  control={control}
                />
                {!!errors?.email?.message && (
                  <FormHelperText error id="email_helper">
                    <>{errors?.email?.message}</>
                  </FormHelperText>
                )}
              </Grid>

              {error && (
                <Box
                  sx={{
                    width: 1,
                    paddingY: 1,
                    border: 1,
                    color: "#9f1831",
                    borderColor: "#9f1831",
                    borderBlockColor: "#9f1831",
                    margin: "20px 0 10px 0",
                  }}
                >
                  <Typography align="center">{t(error)}</Typography>
                </Box>
              )}

              <ButtonSubmit
                type="submit"
                sx={{ my: 1, width: 1, fontWeight: 600 }}
                variant="contained"
                disabled={loading}
              >
                メールアドレスを変更する
              </ButtonSubmit>
            </form>
            {loading && <Loader />}
          </>
        )}
        {step === 1 && <VerificationEmail setStep={setStep} email={email} />}
        {step === 2 && <SuccessUpdateEmail />}
      </Paper>
    </Box>
  );
};

export default ChangeEmail;
