import { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ImageUploader from "../../components/Inputs/ImageUploader";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import { useAccountApi } from "../../custom-hooks/apis/use-account-api";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Loader from "../../components/Loader";
import moment from "moment";
import AuthContext from "../../custom-hooks/use-auth-context";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GlobalDataContext from "../../custom-hooks/use-global-data";
const ProfileForm = (props: any) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const { account } = authCtx;
  const { postAccount } = useAccountApi();

  const defaultValues = {
    nickname: "",
    lastname: "",
    firstname: "",
    middlename: "",
    gender: "",
    current_address: "",
    current_country: "",
    birth_address: "",
    birth_country: "",
    image: "",
    birth_date: moment(authCtx.user?.birthdate).format("YYYY/MM/DD"),
  };
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (account) fillFormValue(account);
  }, [account]);

  const fillFormValue = (account: any) => {
    setValue("nickname", account?.nickname || "");
    setValue("lastname", account?.lastname || "");
    setValue("firstname", account?.firstname || "");
    setValue("middlename", account?.middlename || "");
    setValue("gender", account?.gender || "");
    setValue("current_address", account?.current_address || "");
    setValue("current_country", account?.current_country || "");
    setValue("birth_address", account?.birth_address || "");
    setValue("birth_country", account?.birth_country || "");
    setValue("image", account?.image || "");
  };

  const onSubmit = async (data: FieldValues) => {
    const params = {
      type: 1,
      account_data: {
        firstname: data.firstname,
        lastname: data.lastname,
        middlename: data.middlename || "",
        nickname: data.nickname,
        gender: data.gender,
        current_address: data.current_address,
        current_country: data.current_country,
        birth_country: data.birth_country,
        bitrh_address: data.birth_address,
        account_image_url: data.image.includes("data:image") ? data.image : "",
        previous_image_url: data.image,
      },
    };
    await postAccount(params);
  };

  const handleLogout = () => {
    authCtx.onLogout();
    navigate("/");
  };

  const genderList = [
    { label: "男性", value: "2" },
    { label: "女性", value: "1" },
    { label: "その他", value: "3" },
  ];

  const getOpObj = (option: any) => {
    if (!option?.label)
      option = genderList.find((op: any) => op.label === option.label);
    return option;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container sx={{ paddingBottom: "25px" }}>
          <Paper sx={{ width: 1, paddingX: "10px", paddingY: "20px" }}>
            <Box sx={{ paddingBottom: 5, paddingTop: 3 }}>
              <Controller
                render={({ field }) => <ImageUploader {...field} />}
                name="image"
                control={control}
              />
              {!!errors?.image?.message && (
                <FormHelperText
                  error
                  id="image_helper"
                  sx={{ textAlign: "center" }}
                >
                  <>{errors?.image?.message}</>
                </FormHelperText>
              )}
            </Box>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor="nickname" sx={{ paddingY: 1 }}>
                      ニックネーム
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="nickname"
                      error={!!errors?.nickname?.message}
                    />
                  </FormControl>
                )}
                name="nickname"
                control={control}
              />
              {!!errors?.nickname?.message && (
                <FormHelperText error id="nickname_helper">
                  <>{errors?.nickname?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor="lastname" sx={{ paddingY: 1 }}>
                      名字
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="lastname"
                    />
                  </FormControl>
                )}
                name="lastname"
                control={control}
              />
              {!!errors?.lastname?.message && (
                <FormHelperText error id="lastname_helper">
                  <>{errors?.lastname?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor="firstname" sx={{ paddingY: 1 }}>
                      名前
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="firstname"
                    />
                  </FormControl>
                )}
                name="firstname"
                control={control}
              />
              {!!errors?.firstname?.message && (
                <FormHelperText error id="firstname_helper">
                  <>{errors?.firstname?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="middlename"
                      sx={{ paddingY: 1 }}
                    >
                      ミドルネーム
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="middlename"
                    />
                  </FormControl>
                )}
                name="middlename"
                control={control}
              />
              {!!errors?.middlename?.message && (
                <FormHelperText error id="middlename_helper">
                  <>{errors?.middlename?.message}</>
                </FormHelperText>
              )}
            </Grid>

            {account?.gender && (
              <Grid sx={{ paddingY: 2 }}>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-gender"
                      options={genderList}
                      defaultValue={
                        genderList[
                          genderList.findIndex((item) => {
                            return item.value === account?.gender;
                          })
                        ]
                      }
                      getOptionLabel={(option) => option.label}
                      sx={{ width: 1 }}
                      renderInput={(params) => {
                        return (
                          <>
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input"
                              sx={{ paddingY: 1 }}
                            >
                              性別
                            </InputLabel>
                            <TextField {...params} />
                          </>
                        );
                      }}
                      onChange={(e, value) => {
                        field.onChange(value?.value);
                      }}
                    />
                  )}
                  name="gender"
                  control={control}
                />
                {!!errors?.gender?.message && (
                  <FormHelperText error id="gender_helper">
                    <>{errors?.gender?.message}</>
                  </FormHelperText>
                )}
              </Grid>
            )}
            <Grid>
              <Controller
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      {...field}
                      disabled
                      inputFormat="YYYY/MM/DD"
                      value={moment(authCtx.user?.birthdate).format(
                        "YYYY/MM/DD"
                      )}
                      renderInput={(params) => (
                        <>
                          <InputLabel
                            shrink
                            htmlFor="birth_date_picker"
                            sx={{
                              width: 1,
                              whiteSpace: "inherit",
                              display: "inline",
                              fontSize: "12px",
                            }}
                          >
                            生年月日
                          </InputLabel>
                          <TextField
                            id="birth_date_picker"
                            sx={{ width: 1 }}
                            {...params}
                            placeholder="日/月/年"
                          />
                        </>
                      )}
                    />
                  </LocalizationProvider>
                )}
                name="birth_date"
                control={control}
              />
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="current_address"
                      sx={{ paddingY: 1 }}
                    >
                      現在お住まいの地域（都道府県・市区町村）
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="current_address"
                    />
                  </FormControl>
                )}
                name="current_address"
                control={control}
              />
              {!!errors?.current_address?.message && (
                <FormHelperText error id="current_address_helper">
                  <>{errors?.current_address?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="current_country"
                      sx={{ paddingY: 1 }}
                    >
                      現在お住まいの国
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="current_country"
                    />
                  </FormControl>
                )}
                name="current_country"
                control={control}
              />
              {!!errors?.current_country?.message && (
                <FormHelperText error id="current_country_helper">
                  <>{errors?.current_country?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="birth_address"
                      sx={{ paddingY: 1 }}
                    >
                      出身地（都道府県・市区町村）
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="birth_address"
                    />
                  </FormControl>
                )}
                name="birth_address"
                control={control}
              />
              {!!errors?.birth_address?.message && (
                <FormHelperText error id="birth_address_helper">
                  <>{errors?.birth_address?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="bitrh_country"
                      sx={{ paddingY: 1 }}
                    >
                      出身地（国）
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="birth_country"
                    />
                  </FormControl>
                )}
                name="birth_country"
                control={control}
              />
              {!!errors?.birth_country?.message && (
                <FormHelperText error id="birth_country_helper">
                  <>{errors?.birth_country?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Paper>

          <Paper sx={{ marginY: 2.5 }}>
            <MenuList dense>
              <MenuItem
                disabled={authCtx.user?.provider !== "custom"}
                sx={{ paddingRight: 0 }}
                onClick={() => navigate("/change-email")}
              >
                <ListItemText>
                  <Typography fontSize="small">メールアドレス認証</Typography>
                </ListItemText>
                <ListItemText>
                  <Typography fontSize="small" align="right">
                    {authCtx?.user?.email}
                  </Typography>
                </ListItemText>
                <ListItemIcon>
                  <ArrowForwardIos
                    sx={{
                      float: "right",
                      fontSize: "0.75rem !important",
                      marginLeft: "10px",
                    }}
                  />
                </ListItemIcon>
              </MenuItem>
            </MenuList>
          </Paper>

          <Paper sx={{ marginY: 2.5 }}>
            <MenuList dense>
              <MenuItem onClick={handleLogout} sx={{ paddingRight: 0 }}>
                <ListItemText>
                  <Typography fontSize="small" sx={{ color: "red" }}>
                    ログアウト
                  </Typography>
                </ListItemText>
                <ListItemIcon>
                  <ArrowForwardIos
                    sx={{
                      float: "right",
                      fontSize: "0.75rem !important",
                      marginLeft: "10px",
                    }}
                  />
                </ListItemIcon>
              </MenuItem>
            </MenuList>
          </Paper>
        </Container>

        <Paper
          sx={{
            position: "sticky",
            bottom: "76px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonSubmit
            type="submit"
            sx={{ my: 3, width: 0.8, fontWeight: 600 }}
            variant="contained"
          >
            設定を変更
          </ButtonSubmit>
        </Paper>
      </form>
      {loading && <Loader />}
    </>
  );
};

export default ProfileForm;
