import { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { useTicketApp } from "../../custom-hooks/apis/use-ticket-api";
import AuthContext from "../../custom-hooks/use-auth-context";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import TicketStoreCard from "../../components/TicketStoreCard";
import GlobalDataContext from "../../custom-hooks/use-global-data";

const TicketDetail = () => {
  const [open, setOpen] = useState<boolean>(true);
  const { customer_store_id, ticket_product_id } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { getStoreTicketDetail } = useTicketApp();

  const { postLink } = useTicketApp();

  const { isLoading, data, isError, error } = useQuery(
    [
      QueryKeys.storeTicketDetail,
      authCtx.user?.signInUserSession.idToken,
      ticket_product_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getStoreTicketDetail({
          customer_store_id: customer_store_id,
          product_ticket_id: ticket_product_id,
        });
      return;
    },
    {
      retry: false,
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    const BASE_URL = window.location.protocol + "//" + window.location.host;
    const params = {
      redirect_url: BASE_URL + "/ticket",
      return_url: BASE_URL,
      customer_store_id: customer_store_id,
      type_name: ticket_product_id,
      metadata: {},
    };

    const next_url_raw = await postLink(params);
    let next_url = next_url_raw.payment_link_url;
    if (next_url) {
      setLoading(false);
      window.location.href = next_url;
    }
    // if (next_url) {
    //   setLoading(false);
    //   next_url = next_url.replace(
    //     "https://dtairqisra0sl.cloudfront.net",
    //     "https://f789-202-126-89-202.ngrok-free.app"
    //   );
    //   window.location.href = next_url;
    // }
  };

  if (isError)
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          height: "100vh",
          bgcolor: "#E6E4EB",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Grid
            sx={{
              paddingY: 3,
            }}
          >
            <Typography
              sx={{
                width: 1,
                fontWeight: 550,
                fontSize: "18pt",
              }}
              align="center"
            >
              チケットは存在しません
            </Typography>
          </Grid>

          <Grid
            sx={{
              paddingY: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: 0.9, fontSize: "10pt" }} align="center">
              申し訳ございませんが、チケットの詳細が見つかりませんでした。
            </Typography>
          </Grid>
          <Grid
            sx={{
              paddingY: 3,
            }}
          >
            <ButtonSubmit
              type="submit"
              sx={{ width: 1 }}
              onClick={() => navigate(-1)}
            >
              前のページに戻る
            </ButtonSubmit>
          </Grid>
        </Box>
      </Container>
    );

  return isLoading ? (
    <Loader />
  ) : (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Container
          maxWidth="sm"
          sx={{
            height: `100vh`,
            bgcolor: "#E6E4EB",
            paddingX: 0,
          }}
        >
          <AppBar
            position="static"
            color="transparent"
            elevation={0}
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  navigate("/ticket/store/" + customer_store_id, {
                    state: { refresh_uuid: Date.now() },
                  });
                }}
                sx={{
                  bgcolor: "#fff",
                  border: "1px solid #000",
                }}
              >
                <ArrowBackIosIcon sx={{ height: "0.8em", width: "0.8em" }} />
              </IconButton>
              <Typography
                variant="h6"
                component="h1"
                sx={{
                  color: "#4F4F62",
                  bgcolor: "#fff",
                  paddingY: 0.5,
                  paddingX: 5,
                  borderRadius: 15,
                  fontSize: "12pt",
                }}
              >
                チケット選択
              </Typography>
              <Box sx={{ width: "48px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
          <Box marginX="16px">
            <Box paddingY={5}>
              <TicketStoreCard clickable={false} item={data} />
            </Box>

            <Box sx={{ maxHeight: "calc(100vh - 440px)", overflowY: "scroll" }}>
              <Grid paddingY={3}>
                <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
                  購入した店舗で対象のお酒にご利用いただけます。このチケットで交換できる対象のお酒は各店舗にてご確認ください。
                  ※一部対象外のお酒もございます。
                </Typography>
              </Grid>
              <Grid paddingY={1}>
                <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
                  お酒1杯につき、お酒チケットを1枚ご利用いただけます。
                  使い切りのチケットとなりますので、釣り銭等はご容赦ください。
                </Typography>
              </Grid>

              <Grid paddingY={1}>
                <Typography sx={{ fontWeight: 550, marginY: 1 }}>
                  ご利用上の注意
                </Typography>
                <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
                  他の店舗ではご利用いただけません。
                </Typography>
                <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
                  換金や払い戻しはできません。
                </Typography>
                <Typography sx={{ color: "#7E7E7E", fontSize: "0.8rem" }}>
                  商品品切れの際にはご容赦ください。
                </Typography>
              </Grid>
            </Box>
          </Box>
          <Grid
            paddingY="25px"
            paddingX={4}
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <ButtonSubmit
              fullWidth
              onClick={handleSubmit}
              disabled={loading}
              sx={{
                height: "50px",
                fontSize: "1.1rem",
                fontWeight: 600,
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              決済方法へ進む
            </ButtonSubmit>
          </Grid>
        </Container>
      </>
    </Modal>
  );
};

export default TicketDetail;
