import { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import VerificationPhoneNumber from "./VerificationPhoneNumber";
import SuccessUpdatePhoneNumber from "./SuccessUpdatePhoneNumber";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { MuiTelInput } from "mui-tel-input";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneNumberValidation } from "../../utils/validations";

const ChangePhoneNumber = () => {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const schema = Yup.object().shape({
    phone_number: phoneNumberValidation,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      // const result = await Auth.updateUserAttributes(user, {
      //   phone_number: data.phone_number,
      // });
      const result = await Auth.updateUserAttributes(
        user,
        {
          phone_number: data.phone_number,
        },
        {
          phone_number: data.phone_number,
        }
      );
      setPhoneNumber(data.phone_number);
      setStep(1);
      reset();
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      <Paper sx={{ padding: 3 }}>
        {step === 0 && (
          <>
            <Typography sx={{ fontSize: "0.8rem" }}>
              電話番号を更新する場合は、以下に新しい電話番号を入力してください。入力した電話番号のSMSに認証コード
              が送信されます。
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid sx={{ paddingY: 1, marginTop: 2 }}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth variant="standard">
                      <MuiTelInput
                        disableFormatting
                        {...field}
                        onlyCountries={["JP", "US", "MN"]}
                        id="phone_number"
                        placeholder="電話番号を入力してください"
                      />
                    </FormControl>
                  )}
                  name="phone_number"
                  control={control}
                />
                {!!errors?.phone_number?.message && (
                  <FormHelperText error id="phone_number_helper">
                    <>{errors?.phone_number?.message}</>
                  </FormHelperText>
                )}
              </Grid>

              {error && (
                <Box
                  sx={{
                    width: 1,
                    paddingY: 1,
                    border: 1,
                    color: "#9f1831",
                    borderColor: "#9f1831",
                    borderBlockColor: "#9f1831",
                    margin: "20px 0 10px 0",
                  }}
                >
                  <Typography align="center">{t(error)}</Typography>
                </Box>
              )}

              <ButtonSubmit
                type="submit"
                sx={{ my: 1, width: 1, fontWeight: 600 }}
                variant="contained"
                disabled={loading}
              >
                電話番号を変更する
              </ButtonSubmit>
            </form>
            {loading && <Loader />}
          </>
        )}

        {step === 1 && (
          <VerificationPhoneNumber
            setStep={setStep}
            phoneNumber={phoneNumber}
          />
        )}
        {step === 2 && <SuccessUpdatePhoneNumber />}
      </Paper>
    </Box>
  );
};

export default ChangePhoneNumber;
