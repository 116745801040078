import { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { QrCodeReader } from "../../components/QrReader";
import useWindowDimensions from "../../custom-hooks/use-window-dimensions";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ImportQrTicket = () => {
  const [isOpenQRCamera, setIsOpenQRCamera] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const navigate = useNavigate();
  let { height, width } = useWindowDimensions();

  const handleClose = () => {
    setIsOpenQRCamera(false);
    navigate("/drink");
  };

  return hasError ? (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 56px)`,
        padding: 0,
        position: "relative",
      }}
    >
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: "34px", height: "1px" }}></Box>
          <Box sx={{ width: "34px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          minHeight: `calc(100vh - 146px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid>
          <Grid my={2}>
            <Typography
              sx={{
                width: 1,
                fontWeight: 550,
                fontSize: "18pt",
              }}
              align="center"
            >
              カメラの起動に失敗しました
            </Typography>
          </Grid>
          <Grid my={2}>
            <Typography sx={{ width: 1, fontSize: "12pt" }} align="center">
              カメラを起動できない端末では、チケット取り込み
              機能を御利用頂けません。
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  ) : (
    <Box style={{ width: width, height: height }}>
      {isOpenQRCamera && (
        <QrCodeReader
          title="QRコード読み取り"
          onRead={(e: any) => {
            if (e?.text) {
              setIsOpenQRCamera(false);
              navigate("/ticket-import", {
                state: {
                  qr_string: e?.text,
                },
              });
            }
          }}
          onError={() => setHasError(true)}
          setOpen={handleClose}
        />
      )}
    </Box>
  );
};

export default ImportQrTicket;
