import { Box, Container, Grid, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonRegular from "../../components/Buttons/ButtonRegular";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const FailedLogout = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        height: "100vh",
        bgcolor: "#E6E4EB",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Grid
          sx={{
            marginY: 3,
          }}
        >
          <Typography
            sx={{
              width: 1,
              fontWeight: 550,
              fontSize: "18pt",
            }}
            align="center"
          >
            退会処理に失敗しました
          </Typography>
        </Grid>

        <Grid
          sx={{
            marginY: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ width: 0.9, fontSize: "12pt" }} align="center">
            もう一度やり直すか、管理者に問い合わせしてください。
          </Typography>
        </Grid>

        <Grid
          sx={{
            marginTop: 5,
            marginBottom: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonRegular
            variant="contained"
            sx={{ width: 0.9 }}
            onClick={() => navigate("/account-unsubscribe")}
          >
            退会画面に戻る
          </ButtonRegular>
        </Grid>
        <Grid sx={{ marginY: 1, display: "flex", justifyContent: "center" }}>
          <ButtonSubmit sx={{ width: 0.9 }} onClick={() => navigate("/")}>
            ホームに戻る
          </ButtonSubmit>
        </Grid>
      </Box>
    </Container>
  );
};

export default FailedLogout;
