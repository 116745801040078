import { useContext, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { LOGO } from "../../assets";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import CreateProfile from "../CreateProfile";

const styles = {
  boxContainer: {
    backgroundImage: `url(${LOGO})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100px",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  },
  noCss: {},
};

export default function HomePage() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { getTopPageInfo } = useCommonApi();
  const TOPICS_ID = 8;

  const { data, isLoading } = useQuery([QueryKeys.topPage, TOPICS_ID], () =>
    getTopPageInfo(TOPICS_ID)
  );

  const handleVerificationEmail = async () => {
    const result = await Auth.verifyCurrentUserAttribute("email");
    navigate("/verification-email", {
      state: { email: authCtx?.user?.email, is_verification: true },
    });
  };

  const [isInitialAccess, setInitialAccess] = useState<boolean>(false);

  useEffect(() => {
    if (authCtx.account?.initial_access === "true") setInitialAccess(true);
  }, [authCtx.account?.initial_access]);
  
  if (isLoading) return <Loader />;

  if (isInitialAccess)
    return (
      <CreateProfile
        onSubmit={() => {
          setInitialAccess(false);
          authCtx.setInitialAccess(false);
        }}
      />
    );

  return authCtx.isLoggedIn ? (
    <Box
      style={data?.details?.contents ? styles.noCss : styles.boxContainer}
      sx={{
        minHeight: `calc(100vh - 132px)`,
        paddingX: 2,
      }}
    >
      {data?.details?.contents && (
        <Paper sx={{ padding: 2, marginY: 2 }}>
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.details?.contents,
            }}
          ></Box>
        </Paper>
      )}
      <Grid sx={{ paddingY: 2 }}>
        <Typography align="center">
          <Link to="/commerce" style={{ color: "#26CED5" }}>
            特定商取引法に基づく表記
          </Link>
        </Typography>
      </Grid>
      {authCtx.account?.mail_activate === "false" &&
        authCtx.user?.provider === "custom" && (
          <Paper
            sx={{
              bottom: 90,
              width: 0.9,
              color: "#fff",
              position: "fixed",
              backgroundColor: `rgb(0, 0, 0, 0.7)`,
            }}
          >
            <Grid container sx={{ padding: 2, opacity: 1 }} spacing={1}>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  メールアドレスが認証されていません。
                  右側のボタンをタップして認証してください。
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <ButtonSubmit type="button" onClick={handleVerificationEmail}>
                  認証する
                </ButtonSubmit>
              </Grid>
            </Grid>
          </Paper>
        )}
    </Box>
  ) : (
    <Box
      sx={{
        minHeight: `calc(100vh - 132px)`,
        padding: 2.5,
      }}
    >
      <ButtonSubmit sx={{ width: 1 }} onClick={() => navigate("/login")}>
        ログイン・会具登録
      </ButtonSubmit>

      {data?.details?.contents && (
        <Paper sx={{ padding: 2, marginY: 2 }}>
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.details?.contents,
            }}
          ></Box>
        </Paper>
      )}
      <Grid sx={{ paddingY: 2 }}>
        <Typography align="center">
          <Link to="/commerce" style={{ color: "#26CED5" }}>
            特定商取引法に基づく表記
          </Link>
        </Typography>
      </Grid>
    </Box>
  );
}
