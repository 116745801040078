import { useContext, useEffect, useState } from "react";
import { LOGO } from "../../assets";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import BootstrapInput from "../Inputs/BootstrapInput";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  usernameValidation,
  birthDateValidation,
} from "../../utils/validations";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import moment from "moment";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import AuthContext from "../../custom-hooks/use-auth-context";
import ja from "date-fns/locale/ja";

const SocialRegisterationForm = (props: any) => {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [t] = useTranslation();

  const schema = yup.object().shape({
    nickname: usernameValidation,
    birth_date: birthDateValidation,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (authCtx.user?.birthdate && moment(authCtx.user?.birthdate).isValid()) {
      setValue("birth_date", authCtx.user?.birthdate);
    } else {
      setValue("birth_date", null);
    }
    setValue("nickname", authCtx.user?.username);
  }, []);

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(
        user,
        {
          preferred_username: data.nickname,
          birthdate: moment(data.birth_date).format("YYYY/MM/DD"),
        },
        {
          preferred_username: data.nickname,
          birthdate: moment(data.birth_date).format("YYYY/MM/DD"),
        }
      );
      authCtx.setUserDetail({
        username: data.nickname,
        birthdate: moment(data.birth_date).format("YYYY/MM/DD"),
      });
      props.handleSubmit();
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        bgcolor: "#9f1831",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          paddingX: "10px",
          paddingY: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingY: "20px",
          }}
        >
          <div>
            <img alt="logo" src={LOGO} width={125} height={125} />
          </div>
        </Box>

        <Paper sx={{ width: 1, padding: 2 }} variant="elevation" elevation={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ paddingY: 2 }}>
              <Typography
                component="h1"
                variant="h6"
                align="center"
                sx={{ fontWeight: 600 }}
              >
                登録ありがとうございます
              </Typography>
            </Grid>
            <Grid sx={{ paddingY: 2 }}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                御酒飲帳に登録いただきありがとうございます。以下の情報を入力すると、御酒飲帳の利用が開始できます。
              </Typography>
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor="nickname" sx={{ paddingY: 1 }}>
                      ニックネーム
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="nickname"
                      error={!!errors?.nickname?.message}
                      placeholder="ニックネームを入力してください"
                    />
                  </FormControl>
                )}
                name="nickname"
                control={control}
              />
              {!!errors?.nickname?.message && (
                <FormHelperText error id="nickname_helper">
                  <>{errors?.nickname?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid>
              <Controller
                render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                    dateFormats={{ monthAndYear: "yyyy年 MM月" }}
                  >
                    <DesktopDatePicker
                      {...field}
                      inputFormat="yyyy/MM/dd"
                      renderInput={(params) => (
                        <>
                          <InputLabel
                            shrink
                            sx={{
                              width: 1,
                              whiteSpace: "inherit",
                              display: "inline",
                              fontSize: "9pt",
                            }}
                          >
                            生年月日{" "}
                            <Typography
                              sx={{
                                display: "inline",
                                fontSize: "7pt",
                              }}
                            >
                              ※こちらの項目は一度設定すると変更できません
                            </Typography>
                          </InputLabel>
                          <TextField
                            id="birth_date_picker"
                            sx={{ width: 1 }}
                            {...params}
                            placeholder="日/月/年"
                          />
                        </>
                      )}
                    />
                  </LocalizationProvider>
                )}
                name="birth_date"
                control={control}
              />
              {!!errors?.birth_date?.message && (
                <FormHelperText error id="birth_date_helper">
                  <>{errors?.birth_date?.message}</>
                </FormHelperText>
              )}
            </Grid>

            {error && (
              <Box
                sx={{
                  width: 1,
                  paddingY: 1,
                  border: 1,
                  color: "#9f1831",
                  borderColor: "#9f1831",
                  borderBlockColor: "#9f1831",
                  margin: "35px 0 10px 0",
                }}
              >
                <Typography align="center">{t(error)}</Typography>
              </Box>
            )}

            <ButtonSubmit
              type="submit"
              sx={{ my: 3, width: 1, fontWeight: 600 }}
              variant="contained"
              disabled={loading}
            >
              登録して利用を開始する
            </ButtonSubmit>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default SocialRegisterationForm;
