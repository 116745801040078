import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import moment from "moment";

const ImportTicketComplete = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("🚀 ~ file: index.tsx:10 ~ ImportTicketComplete ~ state:", state);

  // useEffect(() => {
  //   if (!!state?.ticket || !!state?.eka)
  //     navigate(`/ticket-import`, { state: { qr_string: state?.qr_string } });
  // }, [state?.ticket, state?.eka]);

  const end_date = moment
    .unix(state?.eka?.eka_lifespan_end)
    .format("YYYY/MM/DD HH:mm");

  const handleSubmit = () => {
    if (!!state?.ticket || !!state?.eka)
      navigate(`/ticket-import`, { state: { qr_string: state?.qr_string } });
    navigate(
      "/ticket/qr-generate/" +
        state?.eka?.eka_id +
        "?type=" +
        state?.ticket?.type +
        "&span_type=" +
        state?.ticket?.lifespan_type +
        "&title=" +
        state?.ticket?.name +
        "&qty=" +
        state?.eka?.coupon +
        "&end_date=" +
        end_date
    );
  };

  return (
    <Grid
      sx={{
        height: `calc(100vh - 90px)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid>
        <Grid my={2}>
          <Typography
            sx={{
              width: 1,
              fontWeight: 550,
              fontSize: "16px",
            }}
            align="center"
          >
            取り込みが完了しました
          </Typography>
        </Grid>
        <Grid my={2}>
          <Typography
            sx={{
              width: 1,
              fontSize: "12px",
            }}
          >
            引き続きのまっせの御利用をお楽しみください
          </Typography>
        </Grid>
        <Grid my={2}>
          <ButtonSubmit variant="contained" onClick={handleSubmit}>
            取り込んだチケット(QR)を表示する
          </ButtonSubmit>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImportTicketComplete;
