import { useContext, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";
import CloseIcon from "@mui/icons-material/Close";
import TicketStoreCard from "../../components/TicketStoreCard";
import { useTicketApp } from "../../custom-hooks/apis/use-ticket-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import { TICKET_BG_IMG } from "../../assets";

const Ticket = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { customer_store_id } = useParams();
  const { state } = useLocation();
  const [open, setOpen] = useState<boolean>(true);
  const { getStoreTickets, getStoreDetail } = useTicketApp();

  const { isLoading, data } = useQuery(
    [
      QueryKeys.tickets,
      authCtx.user?.signInUserSession.idToken,
      state?.refresh_uuid,
      customer_store_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getStoreTickets({
          customer_store_id: customer_store_id,
          client_id:
            process.env.REACT_APP_AWS_USER_POOLS_FOR_CUSTOMER_CLIENT_ID,
        });
      return;
    }
  );

  const store = useQuery(
    [
      QueryKeys.storeDetail,
      authCtx.user?.signInUserSession.idToken,
      state?.refresh_uuid,
      customer_store_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getStoreDetail({
          customer_store_id: customer_store_id,
        });
      return;
    }
  );

  const styles = {
    backgroundImg: {
      backgroundImage: `url(${store?.data?.image_url || TICKET_BG_IMG})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    noBackgroundImg: {},
  };

  return isLoading || store?.isLoading ? (
    <Loader />
  ) : store.data ? (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          minHeight: "100vh",
          bgcolor: "#E6E4EB",
          paddingX: 0,
        }}
      >
        <Box
          style={
            store?.data?.image_url ? styles.backgroundImg : styles.backgroundImg
          }
          sx={{ height: "300px", position: "relative" }}
        >
          <Grid
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "16px",
              paddingTop: "25px",
            }}
          >
            <Grid>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => navigate("/drink")}
                sx={{
                  backgroundColor: "#fff",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid
              sx={{
                backgroundColor: "#fff",
                paddingX: "10px",
                borderRadius: "50px",
              }}
            >
              チケット選択
            </Grid>
            <Grid sx={{ width: "34px", height: "34px" }}></Grid>
          </Grid>
          <Paper
            sx={{
              margin: "16px",
              padding: 2,
              bottom: 0,
              position: "absolute",
              width: 0.9,
            }}
          >
            <Typography sx={{ fontSize: "10px" }}>
              {store?.data?.prefectures} {store?.data?.municipalities} /{" "}
              {store?.data?.country}
            </Typography>
            <Typography sx={{ fontWeight: 550, fontSize: "15px" }}>
              {store?.data?.name} {store?.data?.subname}
            </Typography>
          </Paper>
          {/* header */}
        </Box>
        {/* body section */}
        {data?.tickets?.length > 0 ? (
          <Box
            sx={{
              padding: 2,
              overflowY: "scroll",
              overflowX: "hidden",
              maxHeight: `calc(100vh - 310px)`,
              paddingBottom: "100px",
            }}
          >
            {data?.tickets.map((item: any) => (
              <Grid marginY={1}>
                <TicketStoreCard
                  item={item}
                  clickable={true}
                  customer_store_id={data?.customer_store_id}
                />
              </Grid>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: `calc(100vh - 400px)`,
            }}
          >
            <Typography sx={{ fontWeight: 600, marginY: 1 }} align="center">
              購入可能なチケットがありません
            </Typography>
            <Typography
              sx={{ width: 0.9, marginY: 1, fontSize: "0.8rem" }}
              align="center"
            >
              この店舗では購入チケットがないか、不具合が発生しています。店舗または管理者に問い合わせてください。
            </Typography>
          </Box>
        )}
      </Container>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          minHeight: "100vh",
          bgcolor: "#E6E4EB",
          paddingX: 0,
        }}
      >
        <Grid
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: "16px",
            paddingTop: "25px",
          }}
        >
          <Grid>
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate("/drink")}
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            sx={{
              backgroundColor: "#fff",
              paddingX: "10px",
              borderRadius: "50px",
            }}
          >
            チケット選択
          </Grid>
          <Grid sx={{ width: "34px", height: "34px" }}></Grid>
        </Grid>
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: `calc(100vh - 150px)`,
          }}
        >
          <Typography sx={{ fontWeight: 600, marginY: 1 }}>
            存在しない店舗です
          </Typography>
          <Typography sx={{ marginY: 1 }}>
            再度店舗を読み込み直してください
          </Typography>
        </Box>
      </Container>
    </Modal>
  );
};

export default Ticket;
