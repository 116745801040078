import React from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Loader from "../../components/Loader";

const Commerce = () => {
  const navigate = useNavigate();
  const { getAppInfo } = useCommonApi();
  const TOPICS_ID = 18;

  const result = useQuery([QueryKeys.commerce, TOPICS_ID], () =>
    getAppInfo(TOPICS_ID)
  );

  return (
    <Paper sx={{ margin: 2, padding: 2 }}>
      {result.isLoading ? (
        <Loader />
      ) : (
        <Typography
          dangerouslySetInnerHTML={{
            __html: result?.data?.details?.contents,
          }}
        ></Typography>
      )}
    </Paper>
  );
};

export default Commerce;
