import { useCallback, useContext, useMemo } from "react";
import appTicketApi from "../../apis/ticket-api";
import GlobalDataContext from "../use-global-data";

export const useTicketApp = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const ticketApi = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_6 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const ticketApi2 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_9 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const ticketApi3 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_10 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const ticketApi4 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_11 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const ticketApi5 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_12 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const ticketApi6 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_16 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const ticketApi7 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_17 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const postLink = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await ticketApi.postLinks(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi]
  );

  const getTickets = useCallback(
    async (params: any) => {
      loadingOp.start();

      const response = await ticketApi2.getTickets(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi2]
  );

  const generateQrCode = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await ticketApi3.generateQrCode(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi3]
  );

  const getStoreTickets = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await ticketApi4.getStoreTickets(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi4]
  );

  const getStoreTicketDetail = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await ticketApi4.getStoreTicketDetail(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi4]
  );

  const getStoreDetail = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await ticketApi5.getStoreDetail(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi5]
  );

  const getTicketStatus = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await ticketApi6.getTicketStatus(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi6]
  );

  const importTicket = useCallback(
    async (params: any) => {
      loadingOp.start();
      try {
        const response = await ticketApi7.importTicket(params);
        return response;
      } catch (e: any) {
        if (e?.request?.response)
          return {
            error: JSON.parse(e?.request?.response).errorMessage,
          };
      } finally {
        loadingOp.finish();
      }
    },
    [ticketApi7]
  );

  return {
    postLink,
    getTickets,
    generateQrCode,
    getStoreTickets,
    getStoreTicketDetail,
    getStoreDetail,
    getTicketStatus,
    importTicket,
  };
};
