import { Box, Container, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const ChangePaymentInformationSuccess = () => {
  const [open, setOpen] = useState<boolean>(true);

  const urlParams = new URLSearchParams(window.location.search);
  const return_url = urlParams.get("return_url");

  const handleBack = () => {
    if (!return_url) return;
    let redirect_url = return_url;
    const paramsObject = Object.fromEntries(urlParams.entries());
    for (const [key, value] of Object.entries(paramsObject)) {
      if (key !== "login_redirect_url") {
        redirect_url += "&" + key + "=" + value;
      }
    }
    window.location.href = redirect_url;
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            height: "100vh",
            bgcolor: "#E6E4EB",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Grid
              sx={{
                paddingY: 3,
              }}
            >
              <Typography
                sx={{
                  width: 1,
                  fontWeight: 550,
                  fontSize: "18pt",
                }}
                align="center"
              >
                支払い情報の変更が完了しました
              </Typography>
            </Grid>

            <Grid
              sx={{
                paddingY: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: 0.9, fontSize: "10pt" }} align="center">
                支払情報の変更が完了しました。引き続き御酒飲板をお楽しみください。
              </Typography>
            </Grid>
            <Grid
              sx={{
                paddingY: 3,
              }}
            >
              <ButtonSubmit
                type="submit"
                sx={{ width: 1 }}
                onClick={handleBack}
              >
                元の画面に戻る
              </ButtonSubmit>
            </Grid>
          </Box>
        </Container>
      </>
    </Modal>
  );
};

export default ChangePaymentInformationSuccess;
