import { useState } from "react";
import { Auth } from "aws-amplify";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Loader from "../../components/Loader";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { verificationCodeValidation } from "../../utils/validations";

const VerificationEmail = (props: any) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const schema = Yup.object().shape({
    verification_code: verificationCodeValidation,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        data.verification_code
      );
      props.setStep(2);
      reset();
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography sx={{ fontSize: "0.8rem" }}>
        メールアドレスの認証コードを{props?.email}
        に送りました。認証コードを入力してください。
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid sx={{ paddingY: 1, marginTop: 2 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="verification_code"
                  type="number"
                  placeholder="コードを入力してください"
                />
              </FormControl>
            )}
            name="verification_code"
            control={control}
          />
          {!!errors?.verification_code?.message && (
            <FormHelperText error id="verification_code_helper">
              <>{errors?.verification_code?.message}</>
            </FormHelperText>
          )}
        </Grid>

        {error && (
          <Box
            sx={{
              width: 1,
              paddingY: 1,
              border: 1,
              color: "#9f1831",
              borderColor: "#9f1831",
              borderBlockColor: "#9f1831",
              margin: "20px 0 10px 0",
            }}
          >
            <Typography align="center">{t(error)}</Typography>
          </Box>
        )}

        <ButtonSubmit
          type="submit"
          sx={{ my: 3, width: 1, fontWeight: 600 }}
          variant="contained"
          disabled={loading}
        >
          確認する
        </ButtonSubmit>

        <Button
          type="button"
          onClick={() => props.setStep(0)}
          sx={{
            width: 1,
            color: "#4B45E2",
            fontWeight: 600,
            borderColor: "#3B4FE6",
          }}
          variant="outlined"
        >
          再送する
        </Button>
      </form>
      {loading && <Loader />}
    </>
  );
};

export default VerificationEmail;
