import { Fragment, useContext } from "react";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { useHistoryApi } from "../../custom-hooks/apis/use-history-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import AuthContext from "../../custom-hooks/use-auth-context";
import RequireAuth from "../../components/RequireAuth";
import moment from "moment";
import Loader from "../../components/Loader";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function HistoryPage() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const { getHistory } = useHistoryApi();

  const { isLoading, data } = useQuery(
    [QueryKeys.history, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getHistory();
      return;
    }
  );
  // console.log("🚀 ~ file: index.tsx:35 ~ HistoryPage ~ histories", histories);

  return authCtx.isLoggedIn ? (
    <Grid item sx={{ paddingX: 2 }}>
      <Demo sx={{ borderRadius: "5px" }}>
        {isLoading ? (
          <Loader />
        ) : data && data.length > 0 ? (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: "0px 3px 5px rgb(0 0 0 / 10%)",
              borderRadius: "5px",
            }}
          >
            {data.map((history: any, index: number) => {
              return (
                <>
                  {index > 0 && <Divider />}
                  <ListItem
                    alignItems="center"
                    key={"history" + history.post_id}
                    onClick={() => navigate("/history/" + history.post_id)}
                  >
                    <ListItemText
                      primary={
                        <Fragment>
                          <Typography
                            fontSize="13px"
                            sx={{ marginBottom: 1.5 }}
                          >
                            {moment
                              .unix(history.created_at)
                              .format("YYYY/MM/DD HH:mm")}
                          </Typography>
                        </Fragment>
                      }
                      secondary={
                        <Fragment>
                          <Typography
                            component="h1"
                            variant="h6"
                            fontSize="15px"
                            color="#000000"
                            fontWeight="bold"
                          >
                            {history.product.sake_brewer} &nbsp;&nbsp;
                            {history.product.name}&nbsp;&nbsp;
                            {history.product.special_designation_sake}{" "}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                            fontSize="10px"
                          >
                            {history.nomasse_owner}
                          </Typography>
                        </Fragment>
                      }
                    />
                    <ListItemIcon sx={{ justifyContent: "end" }}>
                      <ArrowForwardIos
                        sx={{
                          height: "0.8em",
                          width: "0.8em",
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>
                </>
              );
            })}
          </List>
        ) : (
          <Paper
            sx={{
              display: "flex",
              minHeight: `calc(100vh - 200px)`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            履歴はありません
          </Paper>
        )}
      </Demo>
    </Grid>
  ) : (
    <RequireAuth />
  );
}
