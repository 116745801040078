import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface DialogSortProps {
  open: boolean;
  handleClose: () => void;
  selectedSort: number;
  setSelectedSort: (val: number) => void;
}

const DialogSort: React.FC<DialogSortProps> = ({ open, handleClose, selectedSort, setSelectedSort }) => {

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedSort(index);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          variant="h6"
          component="h1"
          sx={{ fontWeight: 600, fontSize: "16px" }}
          align="center"
        >
          購入チケットの並び替え
        </Typography>
      </DialogTitle>
      <DialogContent>
        {" "}
        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton
            selected={selectedSort === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              {selectedSort === 0 && <CheckIcon sx={{ color: "#9F1831" }} />}
            </ListItemIcon>
            <ListItemText primary="発行日が新しい順" />
          </ListItemButton>
          <ListItemButton
            selected={selectedSort === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <ListItemIcon>
                {selectedSort === 1 && <CheckIcon sx={{ color: "#9F1831" }} />}
              </ListItemIcon>
            </ListItemIcon>
            <ListItemText primary="発行日が古い順" />
          </ListItemButton>
        </List>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          autoFocus
          sx={{ color: "#000", fontWeight: 600 }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSort;
