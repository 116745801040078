import { useContext } from "react";
import { Grid } from "@mui/material";
import {
  QR_RED_ICON,
  TICKET_ICON,
  IMPORT_TICKET_ICON,
} from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";
import RequireAuth from "../../components/RequireAuth";
import DrinkMenuItem from "./DrinkMenuItem";

export default function QRPage() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  return authCtx.isLoggedIn ? (
    <Grid sx={{ paddingX: 2 }}>
      <DrinkMenuItem
        title={"チケットを購入する"}
        desc1={"新しくのまっせ用のチケットを購入する方"}
        desc2={"追加でチケットを購入の方"}
        handleClick={() => navigate("/qr-ticket")}
        icon={<img src={TICKET_ICON} alt="ticket_icon" width="35" />}
      />

      <DrinkMenuItem
        title={"発行済のチケットを見る"}
        desc1={"チケットを購入済みの方"}
        desc2={"過去の購入チケットを確認したい方"}
        handleClick={() => navigate("/ticket")}
        icon={<img src={QR_RED_ICON} alt="qr_red_icon" width="35" />}
      />

      <DrinkMenuItem
        title={"チケットを取り込む"}
        desc1={
          "外部から取り込み可能なチケットを御酒飲帳に取り込んで御利用頂けます"
        }
        desc2={""}
        handleClick={() => navigate("/qr-ticket/import")}
        icon={
          <img src={IMPORT_TICKET_ICON} alt="import_ticket_icon" width="35" />
        }
      />
    </Grid>
  ) : (
    <RequireAuth />
  );
}
