import BaseApi from "./base-api";

export default class appCommonApi extends BaseApi {
  async getTopPageInfo(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/top/top_info")).data;
  }

  async getAboutCompanyInfo(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/info/company/management-company")).data;
  }

  async getPrivacyPolicyInfo(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/info/policy/privacy-policy")).data;
  }

  async getTermsOfUseInfo(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/info/term/terms-of-use")).data;
  }

  async getCommerce(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/commerce/commerce")).data;
  }

  async postContact(params: any): Promise<any> {
    return (await this.post("rcms-api/4/form", params)).data;
  }

  async getAppInfo(params: any): Promise<any> {
    return (await this.get("rcms-api/3/app_info/app_info")).data;
  }

  async getManualInfo(topics_id: number): Promise<any> {
    return (await this.get("rcms-api/3/manual/" + topics_id)).data;
  }
}
