import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const SuccessUpdatePhoneNumber = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ paddingBottom: 4 }}>認証が完了しました。</Typography>
      <Button
        type="button"
        onClick={() => navigate("/settings")}
        sx={{
          width: 1,
          color: "#000000",
          fontWeight: 600,
          borderColor: "#000000",
        }}
        variant="outlined"
      >
        設定画面に戻る
      </Button>
    </Box>
  );
};

export default SuccessUpdatePhoneNumber;
