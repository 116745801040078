import {  useState } from "react";
import { Avatar as MUIAvatar, Box, Modal, Typography } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Avatar from "react-avatar-edit";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const ImageUploader = (props: any) => {
  const { value, onChange } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [src, setSrc] = useState(value);
  const [preview, setPreview] = useState(null);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setPreview(null);
  };

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (pv: any) => {
    setPreview(pv);
    onChange(pv);
  };

  const onBeforeFileLoad = (elem: any) => {
    if (elem.target.files[0].size > 716800) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MUIAvatar
        sx={{
          width: "100px",
          height: "100px",
        }}
        src={preview ? preview : value}
        onClick={handleOpen}
      >
        <PhotoCameraIcon />
      </MUIAvatar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" component="h6" variant="h6">
            プロフィールアバターのアップロード
          </Typography>
          <Box>
            {/* <Avatar
              width={330}
              height={300}
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              src={src}
            /> */}

            <ButtonSubmit
              type="button"
              fullWidth
              sx={{ marginY: "10px" }}
              onClick={() => setOpen(false)}
            >
              保存する
            </ButtonSubmit>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageUploader;
