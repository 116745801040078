import { useContext } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ArrowForwardIos } from "@mui/icons-material";
import AuthContext from "../../custom-hooks/use-auth-context";

const AccountManagement = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Box sx={{ paddingX: 2 }}>
      <Paper sx={{ minHeight: "50vh", paddingLeft: "10px" }}>
        <MenuList dense>
          <MenuItem disabled sx={{ paddingY: "20px" }}>
            <ListItemText>アカウント変更</ListItemText>
          </MenuItem>

          <MenuItem
            disabled={authCtx.user?.provider !== "custom"}
            sx={{ marginY: "5px" }}
            onClick={() => navigate("/change-password")}
          >
            <ListItemText>
              <Typography>パスワードを変更する</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "25px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>

          <MenuItem
            sx={{ marginY: "5px" }}
            onClick={() => navigate("/account-unsubscribe")}
          >
            <ListItemText>
              <Typography color="#FF0000">アカウントを退会する</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "25px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
        </MenuList>
        <MenuList dense>
          <MenuItem disabled sx={{ paddingY: "20px" }}>
            <ListItemText>お支払い情報</ListItemText>
          </MenuItem>
        </MenuList>

        <MenuItem
          sx={{ marginY: "5px" }}
          onClick={() => navigate("/payment-method-list")}
        >
          <ListItemText>
            <Typography>お支払い情報を確認する</Typography>
          </ListItemText>
          <ListItemIcon>
            <ArrowForwardIos
              sx={{ minWidth: "25px", fontSize: "1rem !important" }}
              fontSize="small"
            />
          </ListItemIcon>
        </MenuItem>
      </Paper>
    </Box>
  );
};

export default AccountManagement;
