import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const TicketStoreCard = (props: any) => {
  const { item, customer_store_id, clickable } = props;
  const navigate = useNavigate();
  const getDuration = () => {
    if (item?.lifespan_type === 0) {
      if (item?.lifespan_unit_type == 0)
        return item?.lifespan_unit_num + "年間";
      else if (item?.lifespan_unit_type == 1)
        return item?.lifespan_unit_num + "ヶ月間";
      else if (item?.lifespan_unit_type == 2)
        return item?.lifespan_unit_num + "日間";
      else if (item?.lifespan_unit_type == 3)
        return item?.lifespan_unit_num + "時間";
      else if (item?.lifespan_unit_type == 4)
        return item?.lifespan_unit_num + "分間";
      return "";
    }
    const start_date = moment
      .unix(item?.lifespan_start)
      .format("YYYY/MM/DD HH:mm");
    const end_date = moment.unix(item?.lifespan_end).format("YYYY/MM/DD HH:mm");
    return start_date + "~" + end_date + "まで";
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ margin: 0 }}
      onClick={() =>
        clickable
          ? navigate(
              "/ticket/" + customer_store_id + "/" + item?.ticket_product_id
            )
          : {}
      }
    >
      <Grid
        xs={9.5}
        sx={{
          paddingTop: "20px",
          borderRadius: "8px",
          paddingBottom: "16px",
          backgroundColor: "#fff",
          borderRight: "0.12em dashed #c9c9c9",
          boxShadow: "0px 5px 4px -4px rgb(0 0 0 / 30%)",
        }}
      >
        <Grid sx={{ paddingX: 2 }}>
          {item?.lifespan_type === 0 ? (
            <Typography
              sx={{
                fontSize: "9px",
                color: "#000",
              }}
            >
              有効期限：チケット発行から
              {getDuration()}
            </Typography>
          ) : item?.lifespan_type === 1 ? (
            <Typography
              sx={{
                fontSize: "9px",
                color: "#000",
              }}
            >
              有効期限：
              {getDuration()}
            </Typography>
          ) : item?.lifespan_type === 3 ? (
            <Typography
              sx={{
                fontSize: "9px",
                color: "#000",
              }}
            >
              有効期限：無制限
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "9px",
                color: "#000",
              }}
            ></Typography>
          )}
        </Grid>
        <Grid sx={{ paddingX: 2 }}>
          {/* <Typography
            sx={{ mt: 0, mb: -10, display: "inline-block", fontSize: "45px" }}
          >
            {item?.name.replace(/[^0-9]/g, "")}
          </Typography> */}
          <Typography
            sx={{
              display: "inline-block",
              fontSize: "20px",
              margin: "10px 0px 5px 0px",
              paddingLeft: "2px",
              fontWeight: 600,
            }}
          >
            {/* {item?.name.replace(/[0-9]/g, "")} */}
            {item?.name}
          </Typography>
        </Grid>
        <Grid sx={{ paddingX: 2 }}>
          <Typography
            align="right"
            sx={{
              fontSize: "17px",
            }}
          >
            {item?.price}円
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={2.5}
        padding={0}
        sx={{
          color: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 5px 4px -4px rgb(0 0 0 / 30%)",
          backgroundColor: item.type === "coupon" ? "#BC5151" : "#92922D",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography
            sx={{
              textOrientation: "sideways-right",
              writingMode: "vertical-lr",
              transform: "scale(-1)",
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            {item.type === "coupon" ? "チケット" : "飲み放題"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TicketStoreCard;
