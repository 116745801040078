import React, { useEffect, useState } from "react";
import { Container, Paper, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import ChangeCardForm from "../../components/ChangeCardForm";
import { usePaymentApp } from "../../custom-hooks/apis/use-payment-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY || ""
);

const appearance = {
  theme: "stripe",
  rules: {
    ".Label": {
      marginBottom: "6px",
    },
  },
} as Appearance;

const ChangePaymentInformation = () => {
  const { getClientSecret } = usePaymentApp();

  const { data, isLoading } = useQuery(
    [QueryKeys.getClientSecretForUpdating],
    () => getClientSecret()
  );

  return isLoading ? (
    <Loader />
  ) : (
    <Container>
      <Paper sx={{ padding: 3 }}>
        <Typography
          align="center"
          sx={{
            paddingTop: "15px",
            paddingBottom: "25px",
            fontSize: "15pt",
            fontWeight: 700,
          }}
        >
          支払い情報
        </Typography>
        {data?.clientSecret && (
          <Elements
            options={{
              clientSecret: data?.clientSecret,
              locale: "ja",
              appearance: appearance,
            }}
            stripe={stripePromise}
          >
            <ChangeCardForm
              clientSecret={data?.clientSecret}
              redirect_url={window.location.origin}
            />
          </Elements>
        )}
      </Paper>
    </Container>
  );
};

export default ChangePaymentInformation;
