import { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TicketCard from "../../components/TicketCard";
import AuthContext from "../../custom-hooks/use-auth-context";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useTicketApp } from "../../custom-hooks/apis/use-ticket-api";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Loader from "../../components/Loader";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DialogSort from "./DialogSort";

const Ticket = () => {
  const authCtx = useContext(AuthContext);
  const { getTickets } = useTicketApp();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  // filters
  const [used, setUsed] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<number>(0);

  const { isLoading, data, isFetching, refetch } = useQuery(
    [
      QueryKeys.tickets,
      authCtx.user?.signInUserSession.idToken,
      state?.refresh_uuid,
      selectedSort,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getTickets({
          username: authCtx.user.id,
          type: 1,
          sort: selectedSort,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsed(event?.target?.checked);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        paddingX: 0,
      }}
    >
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{ width: 1, display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/drink")}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{ color: "#4F4F62", fontWeight: 600, fontSize: "1.2rem" }}
          >
            チケット
          </Typography>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() =>
              // navigate("/ticket", {
              //   state: {
              //     refresh_uuid: Date.now(),
              //   },
              // })
              refetch()
            }
          >
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLoading || isFetching ? (
        <Loader />
      ) : data?.body?.length > 0 ? (
        <>
          <Box
            sx={{
              paddingX: "16px",
              // maxHeight: `calc(100vh - 290px)`,
              maxHeight: `calc(100vh - 170px)`,
              overflowY: "scroll",
              paddingBottom: "90px",
            }}
          >
            <Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "5px",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    sx={{ mr: 0 }}
                    control={
                      <Checkbox
                        checked={used}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#9F1831",
                          fontWeight: 600,
                        }}
                      >
                        使用済み/期限切れチケット表示
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "10px",
                }}
              >
                <UnfoldMoreIcon sx={{ fontSize: "1rem" }} />
                <Typography
                  onClick={() => {
                    setOpen(true);
                  }}
                  sx={{
                    fontSize: "0.8rem",
                    color: "#9F1831",
                    fontWeight: 600,
                  }}
                >
                  発行日が新しい順
                </Typography>
              </Grid>
            </Grid>
            {data?.body?.map((item: any) => {
              if (!used)
                return item?.used === false && <TicketCard item={item} />;
              return <TicketCard item={item} />;
            })}
          </Box>
          <DialogSort
            open={open}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            handleClose={() => {
              setOpen(false);
            }}
          />
        </>
      ) : (
        <Box
          sx={{
            height: `calc(100vh - 170px)`,
            marginX: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography align="center" sx={{ fontWeight: 550 }}>
              購入したチケットがありません
            </Typography>

            <Typography
              align="center"
              sx={{
                width: 0.8,
                marginX: "auto",
                marginY: 4,
                fontSize: "0.8rem",
              }}
            >
              購入後表示されない場合は、再度この画面を表示し直してください。
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
};
export default Ticket;
