import BaseAwsApi from "./base-aws-api";
import AwsApi from "./aws-api";

export default class appTicketApi extends AwsApi {
  async postLinks(params: any): Promise<any> {
    return await this.post(
      "payment-links/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }
  async getTickets(params: any): Promise<any> {
    return await this.get("drunk/ticket/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async generateQrCode(params: any): Promise<any> {
    return await this.get("qr-genarate/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getStoreTickets(params: any): Promise<any> {
    return await this.get("store/ticket/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getStoreTicketDetail(params: any): Promise<any> {
    return await this.get(
      "store/ticket/detail/" + process.env.REACT_APP_ENVNAME,
      {
        params,
      }
    );
  }

  async getStoreDetail(params: any): Promise<any> {
    return await this.get("store/" + process.env.REACT_APP_ENVNAME, { params });
  }

  async getTicketStatus(params: any): Promise<any> {
    return await this.get("eka/status/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }
  
  async importTicket(params: any): Promise<any> {
    return await this.get("ticket/give/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }
}
