import React, { useContext } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useHistoryApi } from "../../custom-hooks/apis/use-history-api";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import moment from "moment";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";

const HistoryDetail = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { post_id } = useParams();

  const { getHistoryDetail } = useHistoryApi();
  const history_detail_raw = useQuery(
    [QueryKeys.historyDetail, post_id, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken && post_id)
        return getHistoryDetail(post_id);
      return;
    }
  );
  const history_detail =
    history_detail_raw?.data?.length > 0 ? history_detail_raw.data[0] : null;

  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      {history_detail_raw.isLoading ? (
        <Loader />
      ) : (
        <>
          <Paper sx={{ padding: 3 }} elevation={1}>
            <Grid>
              <Typography
                sx={{ fontSize: "10pt", color: "#7E7E7E" }}
                align="center"
              >
                {/* 2023年1月12日 19時51分 */}
                {moment
                  .unix(history_detail?.created_at)
                  .format("YYYY/MM/DD HH:mm")}
              </Typography>
            </Grid>

            <Grid sx={{ paddingTop: 2, paddingBottom: 1 }}>
              <Typography
                sx={{ fontSize: "18pt", fontWeight: "bold" }}
                align="center"
              >
                {history_detail?.product?.name}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontSize: "12pt" }} align="center">
                {history_detail?.product?.special_designation_sake}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                sx={{ fontSize: "12pt", color: "#7E7E7E" }}
                align="center"
              >
                {history_detail?.nomasse_owner}
              </Typography>
            </Grid>

            <Grid sx={{ marginY: 2 }}>
              <hr />
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10pt", color: "#7E7E7E" }}>
                  飲んだ量
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "10pt", fontWeight: 550 }}>
                  {history_detail?.user_drunk_ml}ml
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10pt", color: "#7E7E7E" }}>
                  酒蔵名
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "10pt", fontWeight: 550 }}>
                  {history_detail?.product?.sake_brewery}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper sx={{ padding: 3, marginY: 2 }} elevation={1}>
            <Grid sx={{ paddingBottom: 2 }}>
              <Typography sx={{ fontSize: "12pt", fontWeight: "bold" }}>
                日本酒情報
              </Typography>
            </Grid>

            <Grid container spacing={1} marginX={1}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10pt", color: "#7E7E7E" }}>
                  日本酒ID
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "10pt", fontWeight: 550 }}>
                  {history_detail?.product?.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} marginX={1}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10pt", color: "#7E7E7E" }}>
                  醸造年度
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "10pt", fontWeight: 550 }}>
                  {history_detail?.product?.brewery_year}
                </Typography>
              </Grid>
            </Grid>

            <Grid sx={{ marginTop: 2 }}>
              <ButtonSubmit
                type="button"
                variant="contained"
                fullWidth
                onClick={() => {
                  navigate("/history/alcohol/" + history_detail?.product?.id);
                }}
              >
                詳細情報を見る
              </ButtonSubmit>
            </Grid>
          </Paper>
        </>
      )}
    </Box>
  );
};

export default HistoryDetail;
