import React from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Loader from "../../components/Loader";

const Commerce = () => {
  const navigate = useNavigate();
  const { getCommerce } = useCommonApi();
  const TOPICS_ID = 18;

  const result = useQuery([QueryKeys.commerce, TOPICS_ID], () =>
    getCommerce(TOPICS_ID)
  );

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
      }}
    >
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{ width: 1, display: "flex", justifyContent: "space-between" }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{ color: "#4F4F62", fontWeight: 600, fontSize: "1.2rem" }}
          >
            特定商取引法に基づく表記
          </Typography>
          <Box sx={{ width: "48px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      <Paper sx={{ padding: 2 }}>
        {/* <Typography component="h1" variant="h6" align="center" marginY={1}>
          {result?.data?.details?.ext_1}
        </Typography> */}

        {result.isLoading ? (
          <Loader />
        ) : (
          <Typography
            dangerouslySetInnerHTML={{
              __html: result?.data?.details?.contents,
            }}
          ></Typography>
        )}
      </Paper>
    </Container>
  );
};

export default Commerce;
