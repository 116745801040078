import { useCallback, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import appAccountApi from "../../apis/account-api";
import AuthContext from "../use-auth-context";
import GlobalDataContext from "../use-global-data";

export const useAccountApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);
  const authCtx = useContext(AuthContext);

  const accountApi = useMemo(
    () =>
      new appAccountApi(
        process.env.REACT_APP_API_2 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const accountApi2 = useMemo(
    () => new appAccountApi(process.env.REACT_APP_API_3 || ""),
    []
  );

  const accountApi3 = useMemo(
    () =>
      new appAccountApi(
        process.env.REACT_APP_API_13 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getAccount = useCallback(async () => {
    loadingOp.start();

    const account = await accountApi.getAccount();
    authCtx.setAccountDetail({
      firstname: account.firstname,
      lastname: account.lastname,
      gender: account.gender + "",
      current_address: account.current_address,
      current_country: account.current_country,
      birth_address: account.birth_address,
      birth_country: account.birth_country,
      image: account.account_image_url,
      nickname: account.nickname,
      middlename: account.middlename,
    });
    loadingOp.finish();
    return account;
  }, [accountApi]);

  const postAccount = useCallback(
    async (params: any) => {
      loadingOp.start();
      try {
        const previous_image_url = params?.account_data?.previous_image_url;

        if (params?.account_data?.previous_image_url)
          delete params?.account_data?.previous_image_url;

        const result = await accountApi.postAccount(params);

        if (params?.flag && result?.message) {
          toast.success(result?.message, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "dark",
            style: {
              background: `rgba(0, 0, 0, 0.9)`,
            },
            bodyStyle: {
              background: `rgba(0, 0, 0, 0.9)`,
            },
            progressStyle: {
              background: `rgba(204, 204, 204, 0.9)`,
            },
          });
        } else if (result?.errorType) {
          toast.error(result.errorMessage, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "dark",
            style: {
              background: `rgba(0, 0, 0, 0.9)`,
            },
            bodyStyle: {
              background: `rgba(0, 0, 0, 0.9)`,
            },
            progressStyle: {
              background: `rgba(204, 204, 204, 0.9)`,
            },
          });
        } else if (result?.message) {
          authCtx.setAccountDetail({
            firstname: params.account_data.firstname,
            lastname: params.account_data.lastname,
            gender: params.account_data.gender,
            current_address: params.account_data.current_address,
            current_country: params.account_data.current_country,
            birth_address: params.account_data.bitrh_address,
            birth_country: params.account_data.birth_country,
            nickname: params.account_data.nickname,
            middlename: params.account_data.middlename,
            image:
              params.account_data.account_image_url &&
              params.account_data.account_image_url.trim().length > 0
                ? params.account_data.account_image_url
                : previous_image_url,
          });
          toast.success("設定の変更が完了しました。", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "dark",
            style: {
              background: `rgba(0, 0, 0, 0.9)`,
            },
            bodyStyle: {
              background: `rgba(0, 0, 0, 0.9)`,
            },
            progressStyle: {
              background: `rgba(204, 204, 204, 0.9)`,
            },
          });
        }
        return result;
      } catch (e) {
        // console.log("🚀 ~ file: use-account-api.tsx:17 ~ e", e);
        //   enqueueSnackbar(e?.response?.data?.detail || e?.message, {
        //     variant: "error",
        //   });
        toast.error("エラーが発生しました", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "dark",
          style: {
            background: `rgba(0, 0, 0, 0.9)`,
          },
          bodyStyle: {
            background: `rgba(0, 0, 0, 0.9)`,
          },
          progressStyle: {
            background: `rgba(204, 204, 204, 0.9)`,
          },
        });
      } finally {
        loadingOp.finish();
      }
    },
    [accountApi]
  );

  const getAccountActivate = useCallback(async () => {
    loadingOp.start();
    const accountActivate = await accountApi2.getAccountActivate();
    authCtx.setAccountDetail({
      initial_access: accountActivate.initial_access,
      mail_activate: accountActivate.mail_activate,
    });
    loadingOp.finish();
    return accountActivate;
  }, [accountApi2]);

  const unsubscribeAccount = useCallback(async () => {
    loadingOp.start();
    try {
      const params = { withdrawal: 0 };
      await accountApi3.unsubscribeAccount(params);
      return {
        message: "success",
      };
    } catch (e: any) {
      if (e?.request?.response)
        return {
          error: JSON.parse(e?.request?.response).errorMessage,
        };
    } finally {
      loadingOp.finish();
    }
  }, [accountApi3]);

  return { getAccountActivate, getAccount, postAccount, unsubscribeAccount };
};
