import { Box, Container, Grid, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const CompleteLogout = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        height: "100vh",
        bgcolor: "#E6E4EB",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Grid
          sx={{
            marginY: 3,
          }}
        >
          <Typography
            sx={{
              width: 1,
              fontWeight: 550,
              fontSize: "18pt",
            }}
            align="center"
          >
            御酒飲帳を退会しました
          </Typography>
        </Grid>

        <Grid
          sx={{
            marginY: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ width: 0.9, fontSize: "12pt" }} align="center">
            のまっせをご利用いただきありがとうございます
            また機会がございましたら御酒飲帳をお楽しみください。
          </Typography>
        </Grid>

        <Grid
          sx={{
            marginY: 5,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonSubmit sx={{ width: 0.9 }} onClick={() => navigate("/")}>
            ホームに戻る
          </ButtonSubmit>
        </Grid>
      </Box>
    </Container>
  );
};

export default CompleteLogout;
