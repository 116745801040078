import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { usePaymentApp } from "../../custom-hooks/apis/use-payment-api";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import Loader from "../../components/Loader";
import { useStripe } from "@stripe/react-stripe-js";

const SecondCheckoutForm = (props: any) => {
  const { cardNumberLast4, clientSecret, setAmount, redirect_url } = props;
  const stripe = useStripe();
  const { payQuick } = usePaymentApp();
  const { loading } = useContext(GlobalDataContext);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState<boolean>(false);

  const schema = Yup.object().shape({
    card_number: Yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (stripe) {
      stripe.retrievePaymentIntent(clientSecret).then(function (result) {
        setAmount(result?.paymentIntent?.amount);
        setIsReady(true);
      });
    }
  }, [stripe, clientSecret]);

  const fillFormValue = () => {
    setValue("card_number", "････ ････ ････  " + cardNumberLast4);
  };

  useEffect(() => {
    fillFormValue();
  }, []);

  const onSubmit = async (data: any) => {
    const response = await payQuick({
      client_secret: clientSecret,
    });
    if (response?.error) {
      navigate("/payment-failed");
    } else {
      navigate("/payment-completed", {
        state: {
          redirect_url,
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ paddingY: 0.5 }}>
        <Controller
          render={({ field }) => (
            <FormControl fullWidth variant="standard">
              <InputLabel shrink htmlFor="card_number" sx={{ paddingY: 1 }}>
                力一卜番号
              </InputLabel>
              <BootstrapInput
                {...field}
                sx={{ width: 1 }}
                id="card_number"
                disabled={true}
              />
            </FormControl>
          )}
          name="card_number"
          control={control}
        />
        {!!errors?.card_number?.message && (
          <FormHelperText error id="card_number_helper">
            <>{errors?.card_number?.message}</>
          </FormHelperText>
        )}
      </Grid>
      <Grid sx={{ marginTop: "7px", fontSize: "12px", color: "#7964ED" }}>
        <Link
          to={"/change-payment-information?return_url=" + window.location.href}
        >
          カード情報を変更する
        </Link>
      </Grid>

      <Grid sx={{ paddingTop: 4, paddingBottom: 1 }}>
        <ButtonSubmit
          type="submit"
          sx={{ width: 1, fontWeight: 600 }}
          disabled={loading || !isReady}
        >
          支払う
        </ButtonSubmit>
      </Grid>
      <Grid sx={{ paddingY: 0.5 }}>
        <Typography
          align="center"
          sx={{ fontSize: 11, color: "#4C4B4E", fontWeight: 600 }}
        >
          ボタンをタップすると注文が確定されます
        </Typography>
      </Grid>
      {loading && <Loader />}
    </form>
  );
};

export default SecondCheckoutForm;
