import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Link, useNavigate } from "react-router-dom";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BootstrapInput from "../Inputs/BootstrapInput";
import BootstrapPassword from "../Inputs/BootstrapPassword";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import {
  birthDateValidation,
  confirmPasswordValidation,
  emailValidation,
  passwordValidation,
  usernameValidation,
} from "../../utils/validations";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { FACEBOOK_ICON, GOOGLE_ICON } from "../../assets/icons";
import ButtonGoogle from "../Buttons/ButtonGoogle";
import ja from "date-fns/locale/ja";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [t] = useTranslation();

  const schema = yup.object().shape({
    email: emailValidation,
    username: usernameValidation,
    password: passwordValidation,
    confirm_password: confirmPasswordValidation,
    birth_date: birthDateValidation,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("birth_date", null);
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    // console.log("🚀 ~ file: RegisterForm.tsx:52 ~ onSubmit ~ data", data);
    try {
      const user = await Auth.signUp({
        username: data.email,
        password: data.confirm_password,
        attributes: {
          email: data.email,
          birthdate: moment(data.birth_date).format("YYYY/MM/DD"),
          preferred_username: data.username,
        },
      });
      navigate("/confirm-phone-number", {
        state: {
          username: user?.userSub,
          email: data.email,
          password: data.confirm_password,
        },
        replace: true,
      });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  // async function signInWithGoogle() {
  //   try {
  //     const user = await Auth.federatedSignIn({
  //       provider: CognitoHostedUIIdentityProvider.Google,
  //     });
  //     console.log(user);
  //   } catch (error) {
  //     console.log("Error signing in with Google:", error);
  //   }
  // }

  const signInWithFacebook = async () => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });
    } catch (error) {
      console.log("Error signing in with Facebook:", error);
    }
  };

  return (
    <Box sx={{ width: 1 }}>
      <Typography
        sx={{ paddingY: 1.7 }}
        component="h1"
        variant="h6"
        align="center"
        fontWeight="600"
        marginBottom="20px"
      >
        会員登録をする
      </Typography>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{}}>
            {/* <Typography
              align="center"
              sx={{
                textDecoration: "underline red",
                textUnderlineOffset: "0.5em",
                color: "#000",
                marginBottom: "30px",
              }}
            >
              ほかのサービスで登録する
            </Typography>
            <Box sx={{ marginY: 2 }}>
              <ButtonGoogle
                variant="contained"
                sx={{
                  width: 1,
                  py: 1,
                }}
                onClick={signInWithGoogle}
                startIcon={
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                    }}
                    src={GOOGLE_ICON}
                  />
                }
              >
                Googleでログインする
              </ButtonGoogle>
            </Box> */}
            {/* <Box sx={{ marginY: 2 }}>
              <Button
                variant="contained"
                sx={{
                  width: 1,
                  color: "#fff",
                  justifyContent: "flex-start",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
                onClick={signInWithFacebook}
                startIcon={
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                    }}
                    src={FACEBOOK_ICON}
                  />
                }
              >
                Facebookでログインする
              </Button>
            </Box> */}
          </Box>
          <Grid>
            {/* <Typography
              align="center"
              sx={{
                textDecoration: "underline red",
                textUnderlineOffset: "0.5em",
                color: "#000",
                mt: 6,
                mb: 3,
              }}
            >
              御酒飲帳で登録する
            </Typography> */}
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="email_lbl" sx={{ paddingY: 1 }}>
                    メールアドレス
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    type="email"
                    sx={{ width: 1 }}
                    id="email_lbl"
                    placeholder="メールアドレスを入力してください"
                  />
                </FormControl>
              )}
              name="email"
              control={control}
            />
            {!!errors?.email?.message && (
              <FormHelperText error id="email_helper">
                <>{errors?.email?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="usernameLbl" sx={{ paddingY: 1 }}>
                    ニックネーム
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="usernameLbl"
                    placeholder="ニックネームを入力してください"
                  />
                </FormControl>
              )}
              name="username"
              control={control}
            />
            {!!errors?.username?.message && (
              <FormHelperText error id="username_helper">
                <>{errors?.username?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid>
            <Controller
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ja}
                  dateFormats={{ monthAndYear: "yyyy年 MM月" }}
                >
                  <DesktopDatePicker
                    maxDate={new Date()}
                    {...field}
                    inputFormat="yyyy/MM/dd"
                    renderInput={(params) => (
                      <>
                        <InputLabel
                          shrink
                          sx={{
                            width: 1,
                            whiteSpace: "inherit",
                            display: "inline",
                            fontSize: "9pt",
                          }}
                        >
                          生年月日{" "}
                          <Typography
                            sx={{
                              display: "inline",
                              fontSize: "7pt",
                            }}
                          >
                            ※こちらの項目は一度設定すると変更できません
                          </Typography>
                        </InputLabel>
                        <TextField
                          id="birth_date_picker"
                          sx={{ width: 1 }}
                          {...params}
                        />
                      </>
                    )}
                  />
                </LocalizationProvider>
              )}
              name="birth_date"
              control={control}
            />
            {!!errors?.birth_date?.message && (
              <FormHelperText error id="birth_date_helper">
                <>{errors?.birth_date?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Controller
            render={({ field }) => {
              return (
                <FormControl sx={{ my: 1, width: 1 }}>
                  <InputLabel
                    shrink
                    htmlFor="password"
                    sx={{
                      transform: "none",
                      fontSize: "12px",
                    }}
                  >
                    パスワード
                  </InputLabel>
                  <BootstrapPassword
                    field={field}
                    sx={{ width: 1 }}
                    id="password"
                    placeholder="パスワードを入力してください"
                  />
                </FormControl>
              );
            }}
            name="password"
            control={control}
          />
          {!!errors?.password?.message && (
            <FormHelperText error id="password_helper">
              <>{errors?.password?.message}</>
            </FormHelperText>
          )}

          <Controller
            render={({ field }) => {
              return (
                <FormControl sx={{ my: 1, width: 1 }}>
                  <InputLabel
                    shrink
                    htmlFor="confirm_password"
                    sx={{
                      transform: "none",
                      fontSize: "12px",
                    }}
                  >
                    パスワードの再確認
                  </InputLabel>
                  <BootstrapPassword
                    field={field}
                    sx={{ width: 1 }}
                    id="confirm_password"
                    placeholder="パスワードの再確認を入力してください"
                  />
                </FormControl>
              );
            }}
            name="confirm_password"
            control={control}
          />
          {!!errors?.confirm_password?.message && (
            <FormHelperText error id="confirm_password_helper">
              <>{errors?.confirm_password?.message}</>
            </FormHelperText>
          )}

          <Typography sx={{ fontSize: "0.8rem", marginY: 1 }}>
            アカウントの作成またはログインによって、御酒飲帳の
            <Link to="/terms-of-use" style={{ color: "#26CED5" }}>
              利用規約
            </Link>
            、
            <Link to="/privacy-policy" style={{ color: "#26CED5" }}>
              プライバシー
            </Link>
            ポリシーを理解し、同意したものとします。
          </Typography>
          <Typography sx={{ fontSize: "0.6rem", marginY: 1 }}>
            検証コードの画面が閉じられてしまった場合は、ログイン画面からログインすると再度検証画面が表示されます。
          </Typography>

          {error && (
            <Box
              sx={{
                width: 1,
                paddingY: 1,
                border: 1,
                color: "#9f1831",
                borderColor: "#9f1831",
                borderBlockColor: "#9f1831",
                margin: "35px 0 10px 0",
              }}
            >
              <Typography align="center">{t(error)}</Typography>
            </Box>
          )}

          <ButtonSubmit
            type="submit"
            sx={{ my: 3, width: 1, fontWeight: 600 }}
            variant="contained"
            disabled={loading}
          >
            会員登録
          </ButtonSubmit>
        </form>
      </Box>
    </Box>
  );
};

export default RegisterForm;
