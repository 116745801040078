import { useContext } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import Loader from "../../components/Loader";
import { MuiTelInput } from "mui-tel-input";
import {
  emailValidation,
  messageValidation,
  nameValidation,
  phoneNumberValidation,
} from "../../utils/validations";

const ContactUs = () => {
  const navigate = useNavigate();
  const { postContact } = useCommonApi();
  const { loading } = useContext(GlobalDataContext);

  const schema = Yup.object().shape({
    name: nameValidation,
    email: emailValidation,
    phone_number: phoneNumberValidation,
    message: messageValidation,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    const params = {
      email: data.email,
      name: data.name,
      ext_01: data.phone_number,
      body: data.message,
    };
    postContact(params, () => {
      navigate("/sent-contact-us");
    });
  };

  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ padding: 3 }}>
          <Grid sx={{ paddingY: 1 }}>
            <Typography sx={{ fontSize: "0.8rem" }}>
              御酒飲帳についてお問い合わせがございましたら、お気軽にご連絡ください。
            </Typography>
          </Grid>
          <Grid sx={{ paddingY: 1 }}>
            <Typography sx={{ fontSize: "0.8rem" }}>
              アプリケーション及びお問い合わせに関するプライバシーポリシーは下記よりご確認の上、送信してください。
            </Typography>
          </Grid>

          <Grid sx={{ paddingY: 2 }}>
            <Typography align="center">
              <Link to="/privacy-policy" style={{ color: "#26CED5" }}>
                プライバシーポリシー
              </Link>
            </Typography>
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="name" sx={{ paddingY: 1 }}>
                    お名前
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="name"
                    error={!!errors?.name?.message}
                  />
                </FormControl>
              )}
              name="name"
              control={control}
            />
            {!!errors?.name?.message && (
              <FormHelperText error id="name_helper">
                <>{errors?.name?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="email" sx={{ paddingY: 1 }}>
                    メールアドレス
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="email"
                    type="email"
                    error={!!errors?.email?.message}
                  />
                </FormControl>
              )}
              name="email"
              control={control}
            />
            {!!errors?.email?.message && (
              <FormHelperText error id="email_helper">
                <>{errors?.email?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <InputLabel shrink htmlFor="phone_number" sx={{ paddingY: 1 }}>
              電話番号
            </InputLabel>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  {/* 
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="phone_number"
                    error={!!errors?.phone_number?.message}
                  /> */}

                  <MuiTelInput
                    disableFormatting
                    {...field}
                    onlyCountries={["JP", "US", "MN"]}
                    id="phone_number"
                    placeholder="電話番号を入力してください"
                  />
                </FormControl>
              )}
              name="phone_number"
              control={control}
            />
            {!!errors?.phone_number?.message && (
              <FormHelperText error id="phone_number_helper">
                <>{errors?.phone_number?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="message" sx={{ paddingY: 1 }}>
                    メッセージ
                  </InputLabel>
                  <BootstrapInput
                    multiline
                    rows={4}
                    {...field}
                    id="message"
                    sx={{ width: 1 }}
                    error={!!errors?.message?.message}
                  />
                </FormControl>
              )}
              name="message"
              control={control}
            />
            {!!errors?.message?.message && (
              <FormHelperText error id="message_helper">
                <>{errors?.message?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <ButtonSubmit type="submit" sx={{ width: 1 }} disabled={loading}>
              送信する
            </ButtonSubmit>
          </Grid>
        </Paper>
      </form>
      {loading && <Loader />}
    </Box>
  );
};

export default ContactUs;
