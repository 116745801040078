import { useContext, useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import {
  IMPORT_TICKET_SUCCEED_ICON,
  IMPORT_TICKET_FAILED_ICON,
} from "../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useTicketApp } from "../../custom-hooks/apis/use-ticket-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import moment from "moment";

const ImportTicketConfirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const { getTicketStatus, importTicket } = useTicketApp();
  const { isLoading, data } = useQuery(
    [
      QueryKeys.storeTicketDetail,
      authCtx.user?.signInUserSession.idToken,
      state?.qr_string,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getTicketStatus({
          qr_string: state?.qr_string,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await importTicket({ qr_string: data?.qrString });
      console.log(
        "🚀 ~ file: index.tsx:42 ~ handleSubmit ~ response:",
        response
      );
      setLoading(false);

      if (response?.error)
        navigate(`/ticket-import/failure`, {
          state: {
            qr_string: state?.qr_string,
          },
        });
      else
        navigate(`/ticket-import/succeed`, {
          state: {
            qr_string: state?.qr_string,
            eka: response?.eka,
            ticket: response?.ticket,
          },
        });
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:54 ~ handleSubmit ~ error:", error);
    } finally {
      setLoading(false);
    }
  };
  if (isLoading) return <Loader />;

  return data?.qrString ? (
    <Container sx={{}}>
      <Paper sx={{ padding: 2 }}>
        <Grid my={2} display="flex" justifyContent="center">
          <img src={IMPORT_TICKET_SUCCEED_ICON} alt="import_ticket_icon" />
        </Grid>
        <Grid my={4}>
          <Typography
            sx={{
              width: 1,
              fontSize: "14px",
            }}
            align="center"
          >
            チケット種類：
            {data?.ticketData?.ekaType === "coupon"
              ? "回数券"
              : data?.ticketData?.ekaType === "all_you_can_drink"
              ? "飲み放題"
              : data?.ticketData?.ekaType === "prepaid"
              ? "プリペイド"
              : "その他"}
          </Typography>
          <Typography sx={{ width: 1, fontSize: "14px" }} align="center">
            有効期限：
            {data?.ticketData?.ekaLifespanEnd > 0
              ? moment
                  .unix(data?.ticketData?.ekaLifespanEnd)
                  .format("YYYY/MM/DD HH:mm")
              : "期限なし"}
          </Typography>
          <Typography sx={{ width: 1, fontSize: "14px" }} align="center">
            {data?.ticketData?.ekaType !== "all_you_can_drink" ? (
              <>
                {data?.ticketData?.ekaType === "prepaid"
                  ? "残高："
                  : "残杯数："}
                {data?.ticketData?.ekaLeftCoupon || 0}
                {data?.ticketData?.ekaType === "prepaid" ? "円" : "杯"}
              </>
            ) : (
              <> </>
            )}
          </Typography>
        </Grid>
        <Grid mt={2} mb={5}>
          <Typography sx={{ width: 1, fontSize: "12px", color: "#7E7E7E" }}>
            一度取り込まれたチケットは元に戻せなくなります。予めご了承ください。
          </Typography>
          <Typography sx={{ width: 1, fontSize: "12px", color: "#7E7E7E" }}>
            取り込み完了したチケットは、有効期限内であれば御酒飲帳アプリで引き続き御利用頂けます。
          </Typography>
          <Typography sx={{ width: 1, fontSize: "12px", color: "#7E7E7E" }}>
            チケットは発行した店舗（受け取った店舗・招待対象店舗）でのみ利用可能です。
          </Typography>
        </Grid>

        <Grid my={2}>
          <Typography
            sx={{ width: 1, fontSize: "15px", fontWeight: 550 }}
            align="center"
          >
            チケットを取り込みますか？
          </Typography>
        </Grid>
        <Grid my={2}>
          <ButtonSubmit
            sx={{ width: 1, fontWeight: 550 }}
            onClick={handleSubmit}
            disabled={loading}
          >
            取り込む
          </ButtonSubmit>
        </Grid>
      </Paper>
    </Container>
  ) : (
    <Container sx={{}}>
      <Paper sx={{ padding: 2, paddingBottom: 5 }}>
        <Grid my={2} display="flex" justifyContent="center">
          <img src={IMPORT_TICKET_FAILED_ICON} alt="import_ticket_icon" />
        </Grid>
        <Grid my={4}>
          <Typography
            sx={{
              width: 1,
              fontWeight: 550,
              fontSize: "16px",
            }}
            align="center"
          >
            無効なチケットです
          </Typography>
        </Grid>
        <Grid mt={2} mb={5} display="flex" justifyContent="center">
          <Typography sx={{ width: 0.9, fontSize: "12px" }}>
            すでに有効期限や杯数が切れているか、有効でないチケットが読み込まれました。有効なチケットが読み込めない場合は再度お試しいただき、正常に動作しない場合は管理者にお問い合わせください。
          </Typography>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ImportTicketConfirmation;
