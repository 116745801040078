import { useEffect, useContext, useState } from "react";
import {
  useNavigate,
  Outlet,
  useLocation,
  matchRoutes,
} from "react-router-dom";
import {
  Box,
  Paper,
  CssBaseline,
  BottomNavigation,
  BottomNavigationAction,
  useScrollTrigger,
  Container,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import {
  QR_ICON,
  DRINK_ICON,
  HOME_ICON,
  SETTINGS_ICON,
} from "../../assets/icons";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import { PublicRoutes } from "../../routes";
import LoginIcon from "@mui/icons-material/Login";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RequireAuth from "../RequireAuth";

const PublicLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [currentRoute, setCurrentRoute] = useState<any>(null);
  const trigger = useScrollTrigger();
  const { tab, pageTitle, setCurrentTab, setPageTitle } =
    useContext(GlobalDataContext);

  useEffect(() => {
    setCurrentTab(location.pathname);
    const [{ route }]: any = matchRoutes(PublicRoutes, location);
    setPageTitle(route.title);
    setCurrentRoute(route);
  }, [location]);

  const navigateNotification = () => {
    if (authCtx.isLoggedIn) {
      navigate("/notifications");
    }
  };
  /*
    currentRoute?.headerStyle 
    0 => default navigation 
    1 => default navigation with back button
    3 => top page navigation
  */
  return (
    <Box sx={{ pb: 7, backgroundColor: "#E6E4EB" }}>
      <CssBaseline />
      <Container
        maxWidth="sm"
        sx={{
          minHeight: `calc(100vh - 76px)`,
          bgcolor: "#E6E4EB",
          padding: 0,
        }}
      >
        {currentRoute?.headerStyle === 0 && (
          <AppBar
            position="sticky"
            color="transparent"
            elevation={0}
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "center",
                paddingX: 0,
              }}
            >
              <Typography
                variant="h6"
                component="h1"
                sx={{ color: "#4F4F62", fontWeight: 600, fontSize: "1.2rem" }}
              >
                {pageTitle}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        {currentRoute?.headerStyle === 1 && (
          <AppBar
            elevation={0}
            position="sticky"
            color="transparent"
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => navigate(currentRoute?.backUrl)}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="h1"
                sx={{ color: "#4F4F62", fontWeight: 600, fontSize: "1.2rem" }}
              >
                {pageTitle}
              </Typography>
              <Box sx={{ width: "36px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
        )}
        {currentRoute?.headerStyle === 3 && (
          <AppBar
            position="sticky"
            color="transparent"
            elevation={0}
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "34px", height: "1px" }}></Box>
              <Typography
                variant="h6"
                component="h1"
                sx={{ color: "#4F4F62", fontWeight: 600, fontSize: "1.2rem" }}
              >
                {pageTitle}
              </Typography>
              <IconButton
                size="small"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={navigateNotification}
              >
                <NotificationsNoneIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
        <Box sx={{ minHeight: `calc(100vh - 160px)` }}>
          <Outlet />
        </Box>
      </Container>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: 0,
          height: "76px",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          sx={{ bgcolor: "#9f1831", paddingY: "10px", height: "100%" }}
          value={tab}
          onChange={(event, newValue) => {
            setCurrentTab(newValue);
            navigate(newValue);
          }}
        >
          <BottomNavigationAction
            label="トップ"
            value="/"
            style={{ color: "#fff" }}
            icon={<img src={HOME_ICON} alt="home_icon" width="25" />}
          />
          <BottomNavigationAction
            label="飲む"
            value="/drink"
            style={{ color: "#fff" }}
            icon={<img src={QR_ICON} alt="qr_icon" width="25" />}
          />
          <BottomNavigationAction
            label="履歴"
            value="/history"
            style={{ color: "#fff" }}
            icon={<img src={DRINK_ICON} alt="drink_icon" width="25" />}
          />
          {authCtx.isLoggedIn ? (
            <BottomNavigationAction
              label="設定"
              value="/settings"
              style={{ color: "#fff" }}
              icon={<img src={SETTINGS_ICON} alt="settings_icon" width="25" />}
            />
          ) : (
            <BottomNavigationAction
              label="ログイン"
              value="/login"
              style={{ color: "#fff" }}
              icon={<LoginIcon fontSize="medium" />}
            />
          )}
        </BottomNavigation>
      </Paper>
      {/* </Slide> */}
    </Box>
  );
};

export default PublicLayout;
