import { useContext } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  SAKE_IMG,
  SAKE_DEGREE_IMG,
  RICE_POLISHING_RATIO_IMG,
  ACIDITY_IMG,
} from "../../assets";
import { useHistoryApi } from "../../custom-hooks/apis/use-history-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";

const styles = {
  boxImgContainer: {
    backgroundImage: `url(${SAKE_IMG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "80%",
  },
  sakeDegreeImgContainer: {
    backgroundImage: `url(${SAKE_DEGREE_IMG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
  },
  ricePolishingRatioImgContainer: {
    backgroundImage: `url(${RICE_POLISHING_RATIO_IMG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
  },
  acidityImgContainer: {
    backgroundImage: `url(${ACIDITY_IMG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
  },
};

const AlcoholDetail = () => {
  const authCtx = useContext(AuthContext);
  const { sake_id } = useParams();
  const { getAlcoholDetail } = useHistoryApi();
  const alcohol_detail = useQuery(
    [QueryKeys.alcoholDetail, sake_id, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken && sake_id)
        return getAlcoholDetail(sake_id);
      return;
    }
  );
  return (
    <Box
      sx={{
        paddingX: 2,
        paddingBottom: "40px",
      }}
    >
      {alcohol_detail.isLoading ? (
        <Loader />
      ) : alcohol_detail?.data != "" && alcohol_detail?.data != undefined ? (
        <>
          <Paper sx={{ margin: 0, padding: 0 }}>
            <Grid
              sx={{ backgroundColor: "#9F1831", padding: 2, color: "#fff" }}
            >
              <Typography sx={{ fontSize: "11pt" }}>
                {/* 純米大吟醸 */}
                {alcohol_detail.data?.product?.type}
              </Typography>
              <Typography sx={{ fontSize: "20pt", fontWeight: 550 }}>
                {alcohol_detail.data?.product?.name}
              </Typography>
              <Typography sx={{ fontSize: "11pt" }}>
                {alcohol_detail.data?.product?.special_designation_sake}
              </Typography>
            </Grid>

            <Grid padding={3}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "10pt", color: "#7E7E7E" }}>
                    原料米
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: "13pt" }}>
                    {alcohol_detail.data?.product?.rice_for_sake_making
                      ? alcohol_detail.data?.product?.rice_for_sake_making
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "10pt", color: "#7E7E7E" }}>
                    酵母
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: "13pt" }}>
                    {alcohol_detail.data?.product?.yeast
                      ? alcohol_detail.data?.product?.yeast
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper sx={{ marginY: 2, padding: 3 }} elevation={1}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontSize: "10pt", color: "#7E7E7E", marginBottom: 1 }}
                  align="center"
                >
                  精米歩合
                </Typography>
                <Box
                  height={"100px"}
                  style={styles.ricePolishingRatioImgContainer}
                ></Box>
                <Typography
                  sx={{ fontSize: "10pt", marginTop: 2, fontWeight: 550 }}
                  align="center"
                >
                  （掛）
                  {alcohol_detail.data?.product?.kake_rice_polishing_rate
                    ? alcohol_detail.data?.product?.kake_rice_polishing_rate +
                      "%"
                    : "-"}
                </Typography>
                <Typography
                  sx={{ fontSize: "10pt", fontWeight: 550 }}
                  align="center"
                >
                  （麹）
                  {alcohol_detail.data?.product?.kouji_rice_polishing_rate
                    ? alcohol_detail.data?.product?.kouji_rice_polishing_rate +
                      "%"
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontSize: "10pt", color: "#7E7E7E", marginBottom: 1 }}
                  align="center"
                >
                  日本酒度
                </Typography>
                <Box
                  height={"100px"}
                  style={styles.sakeDegreeImgContainer}
                ></Box>
                {!isNaN(alcohol_detail.data?.product?.sake_metre_value_min) &&
                !isNaN(alcohol_detail.data?.product?.sake_metre_value_max) ? (
                  <Typography
                    sx={{ fontSize: "10pt", marginTop: 2, fontWeight: 550 }}
                    align="center"
                  >
                    {alcohol_detail.data?.product?.sake_metre_value_min}~
                    {alcohol_detail.data?.product?.sake_metre_value_max}
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: "10pt", marginTop: 2, fontWeight: 550 }}
                    align="center"
                  >
                    {alcohol_detail.data?.product?.sake_metre_value_min
                      ? alcohol_detail.data?.product?.sake_metre_value_min
                      : alcohol_detail.data?.product?.sake_metre_value_max
                      ? alcohol_detail.data?.product?.sake_metre_value_max
                      : "-"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{ fontSize: "10pt", color: "#7E7E7E", marginBottom: 1 }}
                  align="center"
                >
                  アルコール度数
                </Typography>
                <Box height={"100px"} style={styles.acidityImgContainer}></Box>
                {alcohol_detail.data?.product?.alcohol_by_volume_min &&
                alcohol_detail.data?.product?.alcohol_by_volume_max ? (
                  <Typography
                    sx={{ fontSize: "10pt", marginTop: 2, fontWeight: 550 }}
                    align="center"
                  >
                    {alcohol_detail.data?.product?.alcohol_by_volume_min}%~
                    {alcohol_detail.data?.product?.alcohol_by_volume_max}%
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: "10pt", marginTop: 2, fontWeight: 550 }}
                    align="center"
                  >
                    {alcohol_detail.data?.product?.alcohol_by_volume_min
                      ? alcohol_detail.data?.product?.alcohol_by_volume_min +
                        "%"
                      : alcohol_detail.data?.product?.alcohol_by_volume_max
                      ? alcohol_detail.data?.product?.alcohol_by_volume_max +
                        "%"
                      : "-"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>

          <Paper sx={{ marginY: 2, padding: 3 }} elevation={1}>
            <Typography sx={{ fontSize: "14pt", fontWeight: "600" }}>
              酒蔵
            </Typography>
            <Grid marginX={2} marginY={1}>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    酒造メーカー
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.brewer?.brewer_name
                      ? alcohol_detail.data?.brewer?.brewer_name
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    酒蔵
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.brewer?.brewery_name
                      ? alcohol_detail.data?.brewer?.brewery_name
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    地域
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.brewer?.brewery_locale
                      ? alcohol_detail.data?.brewer?.brewery_locale
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    都道府県
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.brewer?.brewery_pref
                      ? alcohol_detail.data?.brewer?.brewery_pref
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    市区町村
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.brewer?.brewery_city
                      ? alcohol_detail.data?.brewer?.brewery_city
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper sx={{ marginY: 2, padding: 3 }} elevation={1}>
            <Typography sx={{ fontSize: "14pt", fontWeight: "600" }}>
              酒詳細
            </Typography>
            <Grid marginX={2} marginY={1}>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    釀造年度
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.brewery_year
                      ? alcohol_detail.data?.brewery_year + "年度"
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    貯蔵年数
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.storage_years
                      ? alcohol_detail.data?.storage_years + "年"
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "12pt", color: "#7E7E7E" }}>
                    JANコード
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "12pt" }}>
                    {alcohol_detail.data?.code_jan
                      ? alcohol_detail.data?.code_jan
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: `calc(100vh - 200px)`,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              marginY: 3,
              fontSize: "1.2rem",
            }}
          >
            この日本酒はまだデータがありません
          </Typography>
          <Typography sx={{ fontSize: "0.9rem" }}>
            申し訳ございません。
          </Typography>
          <Typography sx={{ fontSize: "0.9rem" }}>
            この日本酒はまだ詳しい日本酒情報がありません…
          </Typography>
          <Typography sx={{ fontSize: "0.9rem" }}>
            日本酒情報が追加されるまでお待ち下さい。
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AlcoholDetail;
