import { Button, ButtonProps, styled } from "@mui/material";

const ButtonBack = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#000",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "&:focus": {
    backgroundColor: "#fff",
  },
  "&:disabled": {
    opacity: 0.5,
    color: "#ccc",
  },
}));

export default ButtonBack;
