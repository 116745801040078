import React from "react";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationApi } from "../../custom-hooks/apis/use-notification-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import moment from "moment";
import Loader from "../../components/Loader";

const NotificationDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getNotification } = useNotificationApi();

  const result = useQuery([QueryKeys.notification, state.topics_id], () =>
    getNotification(state.topics_id)
  );

  return result.isLoading ? (
    <Loader />
  ) : (
    <Box sx={{ paddingX: 2 }}>
      <Paper sx={{ padding: 2, marginBottom: "50px" }}>
        <Grid marginY={2}>
          <Typography fontSize="12px">
            投稿日
            {moment(result?.data?.details?.inst_ymdhi).format(
              "YYYY/MM/DD HH:mm"
            )}
            &nbsp; 更新日
            {moment(result?.data?.details?.update_ymdhi).format(
              "YYYY/MM/DD HH:mm"
            )}
          </Typography>
        </Grid>
        <Grid marginY={2}>
          <Typography component="h1" variant="h6" sx={{ fontWeight: 550 }}>
            {result?.data?.details?.subject}
          </Typography>
        </Grid>
        <Grid marginY={2}>
          <TypographyExt5
            sx={{ fontSize: "0.8rem" }}
            dangerouslySetInnerHTML={{ __html: result?.data?.details?.ext_3 }}
          ></TypographyExt5>
        </Grid>
      </Paper>
    </Box>
  );
};

const TypographyExt5 = styled(Typography)(({ theme }) => ({
  h2: {
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
  },
  blockquote: {
    backgroundColor: "#E4DFDE",
    margin: "10px",
    padding: "5px 20px",
  },
}));
export default NotificationDetail;
