import { Button, ButtonProps, styled } from "@mui/material";

const ButtonRegular = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#000",
  backgroundColor: "#fff",
  border: "1px solid #000",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "&:disabled": {
    // backgroundColor: "#ccc",
    opacity: 0.5,
    color: "#000",
  },
}));

export default ButtonRegular;
