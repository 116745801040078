import { useContext, useEffect } from "react";
import { LOGO } from "../../assets";
import { Box, Paper, Container } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";

const AuthLayout = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (authCtx?.isLoggedIn) {
      navigate("/");
    }
  }, [authCtx?.isLoggedIn]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        bgcolor: "#9f1831",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          paddingX: "10px",
          paddingY: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingY: "20px",
          }}
        >
          <div>
            <img alt="logo" src={LOGO} width={125} height={125} />
          </div>
        </Box>

        <Paper sx={{ width: 1 }} variant="elevation" elevation={12}>
          <Outlet />
        </Paper>
      </Box>
    </Container>
  );
};

export default AuthLayout;
