import { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import useWindowDimensions from "../../custom-hooks/use-window-dimensions";
import { useNavigate } from "react-router-dom";
import { QrCodeReader } from "../../components/QrReader";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function QRPage() {
  const [code, setCode] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [isOpenQRCamera, setIsOpenQRCamera] = useState<boolean>(true);
  let { height, width } = useWindowDimensions();

  const schema = yup.object().shape({
    code: yup.string().required("コードが入力されていません。"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setIsOpenQRCamera(false);
    navigate("/drink");
  };

  const onSubmit = async (data: any) => {
    navigate("/ticket/store/" + data.code);
  };

  return hasError ? (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          bgcolor: "#fff",
        }}
      >
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{
            bgcolor: "#fff",
            width: 1,
            paddingY: "15px",
            border: 0,
            boxShadow: 0,
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ paddingX: 3 }}>
          <Typography
            sx={{ paddingTop: 4, paddingBottom: 3, fontWeight: 550 }}
            component="h1"
            variant="h6"
            align="center"
          >
            カメラの起動に失敗しました。
          </Typography>
          <Typography sx={{ fontSize: "14px" }} component="div" align="center">
            カメラを起動出来ない場合は、シリアルコードを入力してください。
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid marginY={2}>
              <Controller
                render={({ field }) => (
                  <TextField
                    type="text"
                    {...field}
                    fullWidth
                    id="filled-basic"
                    placeholder="シリアルコードを入力してください"
                  />
                )}
                name="code"
                control={control}
              />
              {!!errors?.code?.message && (
                <FormHelperText error id="code_helper">
                  <>{errors?.code?.message}</>
                </FormHelperText>
              )}
            </Grid>
            <ButtonSubmit fullWidth type="submit">
              OK
            </ButtonSubmit>
          </form>
        </Box>
      </Container>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          bgcolor: "#E8E9EA",
        }}
      >
        <Box style={{ width: width, height: height }}>
          {isOpenQRCamera && (
            <QrCodeReader
              title="QRコード読み取り"
              onRead={(e: any) => {
                if (e?.text) {
                  setIsOpenQRCamera(false);
                  navigate("/ticket/store/" + e?.text);
                }
              }}
              onError={() => setHasError(true)}
              setOpen={handleClose}
            />
          )}
        </Box>
      </Container>
    </Modal>
  );
}
