export const QueryKeys = {
  notification: "getNotification",
  notifications: "getNotifications",
  termsOfUse: "getTermsOfUse",
  commerce: "getCommerce",
  privacyPolicy: "getPrivacyPolicy",
  aboutCompany: "getAboutCompany",
  account: "getAccount",
  accountActivate: "getAccountActivate",
  history: "getHistory",
  historyDetail: "getHistoryDetail",
  alcoholDetail: "getAlcoholDetail",
  redirectPayment: "getRedirectPayment",
  createPayment: "createPayment",
  tickets: "getTickets",
  generateQrCode: "generateQrCode",
  storeTicketDetail: "getStoreTicketDetail",
  storeDetail: "getStoreDetail",
  topPage: "getTopPage",
  getCardInformation: "getCardInformation",
  getClientSecretForUpdating: "getClientSecretForUpdating",
  manual: "getManual",
} as const;
