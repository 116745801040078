import { useContext, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { useAccountApi } from "../../custom-hooks/apis/use-account-api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import {
  birthAddressValidation,
  birthCountryValidation,
  currentAddressValidation,
  currentCountryValidation,
  firstnameValidation,
  genderValidation,
  lastnameValidation,
} from "../../utils/validations";
import ButtonRegular from "../../components/Buttons/ButtonRegular";

const CreateProfile = (props: any) => {
  const { loading } = useContext(GlobalDataContext);
  const [open, setOpen] = useState<boolean>(true);
  const { postAccount } = useAccountApi();

  const schema = yup.object().shape({
    lastname: lastnameValidation,
    firstname: firstnameValidation,
    middlename: yup.string().optional(),
    gender: genderValidation,
    current_address: currentAddressValidation,
    current_country: currentCountryValidation,
    birth_address: birthAddressValidation,
    birth_country: birthCountryValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const genderList = [
    { label: "男性", value: "2" },
    { label: "女性", value: "1" },
    { label: "その他", value: "3" },
  ];

  const getOpObj = (option: any) => {
    if (!option?.label)
      option = genderList.find((op: any) => op.label === option.label);
    return option;
  };

  const onSubmit = async (data: any) => {
    const params = {
      type: 0,
      account_data: {
        firstname: data.firstname,
        lastname: data.lastname,
        middlename: data.middlename || "",
        gender: data.gender,
        current_address: data.current_address,
        current_country: data.current_country,
        birth_country: data.birth_country,
        bitrh_address: data.birth_address,
        nickname: "",
        account_image_url: "",
      },
    };
    await postAccount(params);
    props.onSubmit();
  };

  const skip = async () => {
    const params = {
      flag: "skip",
    };
    await postAccount(params);
    props.onSubmit();
    // call api of skip creation of profile
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflowY: "scroll" }}
    >
      <Container
        maxWidth="sm"
        sx={{
          minHeight: "100vh",
          bgcolor: "#E6E4EB",
        }}
      >
        <AppBar
          position="sticky"
          color="transparent"
          elevation={0}
          sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              ユーザープロフィール作成
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
        <Paper sx={{ padding: 2 }}>
          <Grid>
            <Typography>
              御酒飲帳を利用するにはユーザープロフィールを作成する必要があります。以下の情報を入力してください。
            </Typography>
          </Grid>

          <Grid sx={{ paddingY: 2 }}>
            <ButtonRegular
              variant="contained"
              sx={{ width: 1 }}
              onClick={() => skip()}
            >
              後で入力する
            </ButtonRegular>
          </Grid>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor="lastname" sx={{ paddingY: 1 }}>
                      名字
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="lastname"
                    />
                  </FormControl>
                )}
                name="lastname"
                control={control}
              />
              {!!errors?.lastname?.message && (
                <FormHelperText error id="lastname_helper">
                  <>{errors?.lastname?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor="firstname" sx={{ paddingY: 1 }}>
                      名前
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="firstname"
                    />
                  </FormControl>
                )}
                name="firstname"
                control={control}
              />
              {!!errors?.firstname?.message && (
                <FormHelperText error id="firstname_helper">
                  <>{errors?.firstname?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="middlename"
                      sx={{ paddingY: 1 }}
                    >
                      ミドルネーム ※任意
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      id="middlename"
                    />
                  </FormControl>
                )}
                name="middlename"
                control={control}
              />
              {!!errors?.middlename?.message && (
                <FormHelperText error id="middlename_helper">
                  <>{errors?.middlename?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    id="combo-box-gender"
                    options={genderList}
                    getOptionLabel={(option) => getOpObj(option).label}
                    sx={{ width: 1 }}
                    renderInput={(params) => {
                      return (
                        <>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            sx={{ paddingY: 1 }}
                          >
                            性別
                          </InputLabel>
                          <TextField {...params} />
                        </>
                      );
                    }}
                    onChange={(e, value) => {
                      field.onChange(value?.value);
                    }}
                  />
                )}
                name="gender"
                control={control}
              />
              {!!errors?.gender?.message && (
                <FormHelperText error id="gender_helper">
                  <>{errors?.gender?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="current_address"
                      sx={{ paddingY: 1 }}
                    >
                      現在お住まいの地域（都道府県・市区町村）
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="current_address"
                    />
                  </FormControl>
                )}
                name="current_address"
                control={control}
              />
              {!!errors?.current_address?.message && (
                <FormHelperText error id="current_address_helper">
                  <>{errors?.current_address?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="current_country"
                      sx={{ paddingY: 1 }}
                    >
                      現在お住まいの国
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="current_country"
                    />
                  </FormControl>
                )}
                name="current_country"
                control={control}
              />
              {!!errors?.current_country?.message && (
                <FormHelperText error id="current_country_helper">
                  <>{errors?.current_country?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="birth_address"
                      sx={{ paddingY: 1 }}
                    >
                      出身地（都道府県・市区町村）
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="birth_address"
                    />
                  </FormControl>
                )}
                name="birth_address"
                control={control}
              />
              {!!errors?.birth_address?.message && (
                <FormHelperText error id="birth_address_helper">
                  <>{errors?.birth_address?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <Grid sx={{ paddingY: 2 }}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="birth_country"
                      sx={{ paddingY: 1 }}
                    >
                      出身地（国）
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      type="text"
                      sx={{ width: 1 }}
                      id="birth_country"
                    />
                  </FormControl>
                )}
                name="birth_country"
                control={control}
              />
              {!!errors?.birth_country?.message && (
                <FormHelperText error id="birth_country_helper">
                  <>{errors?.birth_country?.message}</>
                </FormHelperText>
              )}
            </Grid>

            <ButtonSubmit
              type="submit"
              sx={{ my: 2, width: 1, fontWeight: 600 }}
              variant="contained"
              disabled={loading}
            >
              プロフィールの作成
            </ButtonSubmit>
          </form>
        </Paper>
      </Container>
    </Modal>
  );
};

export default CreateProfile;
