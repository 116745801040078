import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import PasswordField from "../../components/Inputs/PasswordField";
import { Controller, useForm } from "react-hook-form";
import AuthContext from "../../custom-hooks/use-auth-context";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  confirmPasswordValidation,
  passwordValidation,
  verificationCodeValidation,
} from "../../utils/validations";

const ResetPassword = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const authCtx = useContext(AuthContext);
  const [t] = useTranslation();

  const schema = Yup.object().shape({
    verification_code: verificationCodeValidation,
    password: passwordValidation,
    confirm_password: confirmPasswordValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!state?.email) navigate("/login", { replace: true });
  }, [state?.email]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(
        state.email,
        data.verification_code,
        data.confirm_password
      );
      navigate("/", { replace: true });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{ paddingY: 2.5 }}
          component="h1"
          variant="h6"
          align="center"
        >
          パスワードを変更する
        </Typography>
        <Typography sx={{ fontSize: "0.8rem", marginBottom: 2 }}>
          パスワードのリセットコードを{state?.email}
          にメールで送りました。リセットコードと新しいパスワードを入力してください。
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              sx={{ my: 1 }}
              type="number"
              id="filled-basic"
              placeholder="リセットコードを入力してください"
            />
          )}
          name="verification_code"
          control={control}
        />
        {!!errors?.verification_code?.message && (
          <FormHelperText error id="verification_code_helper">
            <>{errors?.verification_code?.message}</>
          </FormHelperText>
        )}
        <Controller
          render={({ field }) => {
            return (
              <FormControl sx={{ my: 1, width: 1 }}>
                <PasswordField
                  field={field}
                  id={"password"}
                  placeholder={"新しいパスワード"}
                />
              </FormControl>
            );
          }}
          name="password"
          control={control}
        />
        {!!errors?.password?.message && (
          <FormHelperText error id="password_helper">
            <>{errors?.password?.message}</>
          </FormHelperText>
        )}
        <Controller
          render={({ field }) => {
            return (
              <FormControl sx={{ my: 1, width: 1 }}>
                <PasswordField
                  field={field}
                  id={"password"}
                  placeholder={"新しいパスワードの再確認"}
                />
              </FormControl>
            );
          }}
          name="confirm_password"
          control={control}
        />
        {!!errors?.confirm_password?.message && (
          <FormHelperText error id="confirm_password_helper">
            <>{errors?.confirm_password?.message}</>
          </FormHelperText>
        )}
        {error && (
          <Box
            sx={{
              width: 1,
              paddingY: 1,
              border: 1,
              color: "#9f1831",
              borderColor: "#9f1831",
              borderBlockColor: "#9f1831",
              margin: "10px 0 10px 0",
            }}
          >
            <Typography align="center">{t(error)}</Typography>
          </Box>
        )}
        <ButtonSubmit
          type="submit"
          sx={{ my: 1, width: 1, fontWeight: 600 }}
          variant="contained"
          disabled={loading}
        >
          パスワードを変更
        </ButtonSubmit>
      </form>
    </Box>
  );
};

export default ResetPassword;
