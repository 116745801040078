import pages from "./pages";
import components from "./components";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "./components/Auth/Layout";
import PublicLayout from "./components/Layout/PublicLayout";

const {
  HomePage,
  DrinkPage,
  HistoryPage,
  HistoryDetailPage,
  AlcoholDetailPage,
  SettingsPage,
  AppInfoPage,
  LoginPage,
  ConfirmPhoneNumberPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ProfilePage,
  AccountManagementPage,
  AccountUnsubscribePage,
  PaymentPage,
  QrTicketPage,
  ChangePasswordPage,
  ChangeEmailPage,
  ChangePhoneNumberPage,
  NotificationsPage,
  NotificationDetailPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  AboutPage,
  VerificationEmailPage,
  SuccessUpdateEmailPage,
  ContactUsPage,
  SentContactUsPage,
  PaymentSelectMethodPage,
  PaymentCompletedPage,
  TicketDetailPage,
  PaymentFailedPage,
  PaymentDonePage,
  TicketsPage,
  QrGeneratePage,
  TicketStorePage,
  NotFoundErrorPage,
  CommercePage,
  CompleteLogoutPage,
  FailedLogoutPage,
  PaymentMethodListPage,
  ChangePaymentInformationPage,
  ChangePaymentInformationSuccessPage,
  ImportQrTicketPage,
  ImportTicketConfirmationPage,
  ImportTicketCompletePage,
  ImportTicketFailurePage,
  ManualRegistPage,
  ManualLoginPage,
  ManualRecordPage,
  ManualEnjoyPage,
} = pages;

const { Layout } = components;

export const PrivateRoutes = [
  {
    path: "/drink",
    key: "drink-page",
    title: "飲む",
    headerStyle: 0,
    component: <DrinkPage />,
  },
  {
    path: "/history",
    key: "history-page",
    title: "履歴",
    headerStyle: 0,
    component: <HistoryPage />,
  },
  {
    path: "/history/:post_id",
    key: "history-detail-page",
    title: "履歴",
    backUrl: "/history",
    headerStyle: 1,
    component: <HistoryDetailPage />,
  },
  {
    path: "/history/alcohol/:sake_id",
    key: "alcohol-detail-page",
    title: "日本酒情報",
    backUrl: -1,
    headerStyle: 1,
    component: <AlcoholDetailPage />,
  },
  {
    path: "/settings",
    key: "settings-page",
    title: "設定",
    headerStyle: 0,
    component: <SettingsPage />,
  },
  {
    path: "/app-info",
    key: "app-info-page",
    title: "このアプリについて",
    backUrl: -1,
    headerStyle: 1,
    component: <AppInfoPage />,
  },
  {
    path: "/profile",
    key: "profile-page",
    title: "プロフィール変更",
    backUrl: "/settings",
    headerStyle: 1,
    component: <ProfilePage />,
  },
  {
    path: "/account-management",
    key: "account-management-page",
    title: "プロフィール変更",
    headerStyle: 1,
    backUrl: "/settings",
    component: <AccountManagementPage />,
  },
  {
    path: "/account-unsubscribe",
    key: "account-unsubscribe-page",
    title: "退会",
    backUrl: "/account-management",
    headerStyle: 1,
    component: <AccountUnsubscribePage />,
  },
  {
    path: "/payment",
    key: "payment-page",
    title: "",
    headerStyle: 0,
    component: <PaymentPage />,
  },
  {
    path: "/qr-ticket",
    key: "qr-ticket-page",
    title: "",
    headerStyle: 0,
    component: <QrTicketPage />,
  },
  {
    path: "/change-password",
    key: "change-password-page",
    title: "パスワードの変更",
    backUrl: "/account-management",
    headerStyle: 1,
    component: <ChangePasswordPage />,
  },
  {
    path: "/change-email",
    key: "change-email-page",
    title: "メールアドレスの変更",
    headerStyle: 1,
    backUrl: "/profile",
    component: <ChangeEmailPage />,
  },
  {
    path: "/verification-email",
    key: "verification-email-page",
    title: "",
    headerStyle: 0,
    component: <VerificationEmailPage />,
  },
  {
    path: "/successfully-updated-email",
    key: "successfully-updated-email-page",
    title: "",
    headerStyle: 0,
    component: <SuccessUpdateEmailPage />,
  },
  {
    path: "/change-phone-number",
    key: "change-phone-number-page",
    title: "電話番号の変更",
    headerStyle: 1,
    backUrl: "/profile",
    component: <ChangePhoneNumberPage />,
  },
  {
    path: "/notifications",
    key: "notifications-page",
    title: "お知らせ",
    headerStyle: 1,
    backUrl: "/",
    component: <NotificationsPage />,
  },
  {
    path: "/notifications/:id",
    key: "notification-detail-page",
    title: "お知らせ",
    headerStyle: 1,
    backUrl: "/notifications",
    component: <NotificationDetailPage />,
  },
  {
    path: "/management-company",
    key: "management-company-page",
    title: "運営会社概要",
    headerStyle: 1,
    backUrl: -1,
    component: <AboutPage />,
  },
  {
    path: "/contact-us",
    key: "contact-us-page",
    title: "お問い合わせ",
    headerStyle: 1,
    backUrl: "/settings",
    component: <ContactUsPage />,
  },
  {
    path: "/sent-contact-us",
    key: "sent-contact-us-page",
    title: "お問い合わせ",
    headerStyle: 1,
    backUrl: "/contact-us",
    component: <SentContactUsPage />,
  },
  {
    path: "/ticket",
    key: "ticket-page",
    headerStyle: 0,
    component: <TicketsPage />,
  },
  {
    path: "/ticket/qr-generate/:eka_id",
    key: "qr-generate-page",
    headerStyle: 0,
    component: <QrGeneratePage />,
  },
  {
    path: "/ticket/store/:customer_store_id",
    key: "ticket-store-page",
    headerStyle: 0,
    component: <TicketStorePage />,
  },
  {
    path: "/ticket/:customer_store_id/:ticket_product_id",
    key: "ticket-detail-page",
    headerStyle: 0,
    component: <TicketDetailPage />,
  },
  {
    path: "/payment-method-list",
    key: "payment-method-list-page",
    title: "お支払い方法",
    headerStyle: 1,
    backUrl: "/account-management",
    component: <PaymentMethodListPage />,
  },
  {
    path: "/change-payment-information",
    key: "change-payment-information-page",
    title: "お支払い方法",
    headerStyle: 1,
    backUrl: -1,
    component: <ChangePaymentInformationPage />,
  },
  {
    path: "/change-payment-information-success",
    key: "change-payment-information-page",
    headerStyle: 0,
    component: <ChangePaymentInformationSuccessPage />,
  },
  {
    path: "/qr-ticket/import",
    key: "import-qr-ticket-page",
    hideFooterBar: true,
    headerStyle: 0,
    component: <ImportQrTicketPage />,
  },
  {
    path: "/ticket-import/",
    key: "import-ticket-confirmation-page",
    title: "チケット取り込み確認",
    backUrl: "/qr-ticket/import",
    headerStyle: 1,
    component: <ImportTicketConfirmationPage />,
  },
  {
    path: "/ticket-import/succeed",
    key: "import-ticket-succeed-page",
    component: <ImportTicketCompletePage />,
  },
  {
    path: "/ticket-import/failure",
    key: "import-ticket-failure-page",
    component: <ImportTicketFailurePage />,
  },
  {
    path: "/payment/select",
    key: "payment-select-method-page",
    headerStyle: 0,
    component: <PaymentSelectMethodPage />,
  },
];

export const AuthRoutes = [
  {
    path: "/login",
    key: "login2-page",
    component: <LoginPage />,
  },
  {
    path: "/login/quick",
    key: "login-quick-page",
    component: <LoginPage />,
  },
  {
    path: "/confirm-phone-number",
    key: "confirm-phone-number",
    component: <ConfirmPhoneNumberPage />,
  },
  {
    path: "/forgot-password",
    key: "forgot-password",
    component: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    key: "reset-password",
    component: <ResetPasswordPage />,
  },
];

export const PublicRoutes = [
  {
    path: "/",
    key: "home-page",
    title: "トップ",
    headerStyle: 3,
    component: <HomePage />,
  },
  {
    path: "/commerce",
    key: "commerce-page",
    component: <CommercePage />,
  },
  {
    path: "/privacy-policy",
    key: "privacy-policy-page",
    title: "プライバシーポリシー",
    headerStyle: 1,
    backUrl: -1,
    component: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-of-use",
    key: "terms-of-use-page",
    title: "利用規約",
    headerStyle: 1,
    backUrl: -1,
    component: <TermsOfUsePage />,
  },
  {
    path: "/complete-logout",
    key: "complete-logout-page",
    component: <CompleteLogoutPage />,
  },
  {
    path: "/failed-logout",
    key: "failed-logout-page",
    component: <FailedLogoutPage />,
  },
  {
    path: "/payment-completed",
    key: "payment-completed-page",
    component: <PaymentCompletedPage />,
  },
  {
    path: "/payment-failed",
    key: "payment-failed-page",
    component: <PaymentFailedPage />,
  },
  {
    path: "/payment-done",
    key: "payment-done-page",
    component: <PaymentDonePage />,
  },
  {
    path: "*",
    key: "not-found",
    component: <NotFoundErrorPage />,
  },
  {
    path: "/manual/regist",
    key: "manual-regist-page",
    title: "御酒飲帳の会員登録追加手順",
    headerStyle: 1,
    backUrl: -1,
    component: <ManualRegistPage />,
  },
  {
    path: "/manual/login",
    key: "manual-login-page",
    title: "御酒飲帳のログイン手順",
    headerStyle: 1,
    backUrl: -1,
    component: <ManualLoginPage />,
  },
  {
    path: "/manual/record",
    key: "manual-record-page",
    title: "御酒飲帳の「飲んだ履歴」確認手順",
    headerStyle: 1,
    backUrl: -1,
    component: <ManualRecordPage />,
  },
  {
    path: "/manual/enjoy",
    key: "manual-enjoy-page",
    title: "御酒飲帳で日本酒を楽しむ",
    headerStyle: 1,
    backUrl: -1,
    component: <ManualEnjoyPage />,
  },
];

const AllRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />} key="Layout">
        {PrivateRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
      <Route element={<AuthLayout />} key="AuthLayout">
        {AuthRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
      <Route element={<PublicLayout />} key="PublicLayout">
        {PublicRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
    </Routes>
  );
};
export default AllRoutes;
