export const awsExports = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  // aws_user_pools_web_client_id: "22aigb5q3hrqnlhi35b69c2b6a",
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN || "",
    scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    redirectSignIn:
      process.env.REACT_APP_REDIRECT_SIGN_IN || "http://localhost:3000",
    redirectSignOut:
      process.env.REACT_APP_REDIRECT_SIGN_OUT || "http://localhost:3000",
    responseType: "code",
    facebook: {
      appId: "218754687508737",
      appSecret: "97f724934789cba0fdae76fdd96f7d25",
      scope: ["email", "public_profile", "user_birthday"],
    },
  },
  federationTarget: "COGNITO_USER_POOLS",
  authenticationFlowType: "USER_SRP_AUTH",
};
